<template>
  <div class="card-body" style="height: 390px">
    <div class="text-center col-12">
      <div class="chart">
        <canvas :id="chartId" class="chart-canvas" height="250"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { reactive, watch, onMounted, onUnmounted } from "vue";

export default {
  name: "ESG-Donut",
  props: {
    chartId: {
      type: String,
      required: true,
    },
    esgGA: {
      type: Number,
    },
    esgGB: {
      type: Number,
    },
    esgGC: {
      type: Number,
    },
    Year: {
      type: Number,
    },
  },

  setup(props) {
    const chartLine = reactive({
      esgGA: props.esgGA,
      esgGB: props.esgGB,
      esgGC: props.esgGC,
    });

    let chartInstance = null;

    const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = Object.values(chartLine);
        chartInstance.update();
      }
    };

    watch(
      () => [props.esgGA, props.esgGB],
      function () {
        chartLine.esgGA = props.esgGA;
        chartLine.esgGB = props.esgGB;
         chartLine.esgGC = props.esgGC;
        updateChart();
      }
    );

    const createChart = () => {
      const canvas = document.getElementById(props.chartId);
      if (canvas) {
        const ctx = canvas.getContext("2d");

        var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
        gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
        gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors

        chartInstance = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: ["Environment", "Social","Governanace"],
            datasets: [
              {
                label: "Consumption",
                weight: 7.0,
                cutout: 80,
                tension: 0,
                pointRadius: 0.2,
                borderWidth: 0,
                backgroundColor: ["#5cb85c","#5e72e4", "#11cdef"],

                data: Object.values(chartLine),
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "bottom", // Adjust the legend position if needed
                padding: 50, 
                labels: {
                  color: "#ffffff", // Change the legend font color
                },
              },
            },
     

            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: true,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        });
      }
    };

    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return {
      chartLine,
      updateChart,
    };
  },
};
</script>
