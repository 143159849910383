<template>
  <div class="card-body" style="height: 190px">
    <div class="text-center col-12">
      <div class="chart">
        <canvas :id="chartId" class="chart-canvas" height="150"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { reactive, watch, onMounted, onUnmounted } from "vue";

export default {
  name: "pie-chart",
  props: {
    chartId: {
      type: String,
      required: true,
    },
    esgGA: {
      type: Number,
    },
    esgGB: {
      type: Number,
    },
    Year: {
      type: Number,
    },
    stitle: {
      type: String,
    },
  },

  setup(props) {
    const chartLine = reactive({
      esgGA: props.esgGA,
      esgGB: props.esgGB,
    });

    let chartInstance = null;

    const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = Object.values(chartLine);
        chartInstance.update();
      }
    };

    watch(
      () => [props.esgGA, props.esgGB],
      function () {
        chartLine.esgGA = props.esgGA;
        chartLine.esgGB = props.esgGB;
        updateChart();
      }
    );

    const createChart = () => {
      const canvas = document.getElementById(props.chartId);
      if (canvas) {
        const ctx = canvas.getContext("2d");

        var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
        gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
        gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors

        chartInstance = new Chart(ctx, {
          type: "pie",
          data: {
            labels: ["diverted ", "disposal"],
            datasets: [
              {
                label: "Consumption",
                borderWidth: 0,
                backgroundColor: ["#11cdef", "#df4a43"],

                data: Object.values(chartLine),
                fill: true,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  color: "#ffffff", // Set the legend text color to red (#ff0000)
                },
              },
              title: {
                display: true,
                text: props.stitle,
                color: "#ffffff",
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: true,
                  display: true,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        });
      }
    };

    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return {
      chartLine,
      updateChart,
    };
  },
};
</script>
