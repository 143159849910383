import { createRouter, createWebHistory } from 'vue-router';
import dashboard from '../views/Dashboard.vue';
import Profile from '../views/Profile.vue';
import AuthComponent from '@/components/Auth.vue';
import Logout from '../views/Logout.vue';
import Questions from '@/views/Questions.vue';
import EditQuestions from '@/views/EditQuestions.vue';
import createQuestions from '@/views/createQuestion.vue';
import highlight from '@/views/KeyHighlight.vue';
import tgpt from '@/views/testgpt.vue';
import pdf from '@/views/PDF.vue';
import { Auth } from 'aws-amplify';


const routes = [
  {
    path: '',
    redirect: '/dashboard'
  },

  {
    path: '/dashboard',
    component: dashboard,
    name: 'ESG Journey',
    meta: { requiresAuth: true }
  },
  {
    path: '/Objective',
    
    beforeEnter() {
      window.open('https://portocol.io/', '_blank');
    }
  },
  {
    path: '/profile',
    name: 'Account',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/editquestion/:id',
    name: 'edit-question',
    component: EditQuestions,
    meta: { requiresAuth: true }
  },
  {
    path: '/Measures',
    name: 'Questions',
    component: Questions,
    meta: { requiresAuth: true }
  },
  {
    path: '/KeyHighlights',
    name: 'highlights',
    component: highlight,
    meta: { requiresAuth: true }
  },
  {
    path: '/ESG-Wizard',
    name: 'pdf',
    component: pdf,
    meta: { requiresAuth: true }
  },
  {
    path: '/testgpt',
    name: 'tgpt',
    component: tgpt,
    meta: { requiresAuth: true }
  },
  {
    path: '/createQuestions',
    name: 'createQuestions',
    component: createQuestions,
    meta: { requiresAuth: true }
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: { requiredAuth: true, }
  },
  {
    path: '/auth',
    name: 'AuthComponent',
    component: AuthComponent
  },




];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {
    try {
      await Auth.currentAuthenticatedUser();
      next();
    } catch (error) {
      next({ path: '/auth' });
    }
  } else {
    next();
  }
});

export default router;