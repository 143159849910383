/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEsgScore = /* GraphQL */ `
  mutation CreateEsgScore(
    $input: CreateEsgScoreInput!
    $condition: ModelEsgScoreConditionInput
  ) {
    createEsgScore(input: $input, condition: $condition) {
      id
      Esg_rating
      Esg_score
      Esg_dif
      year
      userid
      createdAt
      updatedAt
    }
  }
`;
export const updateEsgScore = /* GraphQL */ `
  mutation UpdateEsgScore(
    $input: UpdateEsgScoreInput!
    $condition: ModelEsgScoreConditionInput
  ) {
    updateEsgScore(input: $input, condition: $condition) {
      id
      Esg_rating
      Esg_score
      Esg_dif
      year
      userid
      createdAt
      updatedAt
    }
  }
`;
export const deleteEsgScore = /* GraphQL */ `
  mutation DeleteEsgScore(
    $input: DeleteEsgScoreInput!
    $condition: ModelEsgScoreConditionInput
  ) {
    deleteEsgScore(input: $input, condition: $condition) {
      id
      Esg_rating
      Esg_score
      Esg_dif
      year
      userid
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      userid
      c1ag1
      c1b
      c1c
      c2a
      c2b
      c3ag11a
      c3ag11b
      c3ag12a
      c3ag12b
      c3ag12c
      c3ag12d
      c3ag21a
      c3ag21b
      c3ag22d
      c3ag22c
      c3ag22b
      c3ag22a
      c3b1a
      c3b1b
      c3b2a
      c3b2b
      c3b2c
      c3b2d
      c4a
      c5a
      c5b
      c5c
      c6ag1
      c6ag2
      c6b
      c6cg1
      c6cg2
      c6d
      c7a
      c8a
      c9a
      c10a
      c10a1
      c10a2
      c11a
      c11b
      c11c
      s1a
      s1b
      s1c
      s1d
      s2a
      s3a
      s3b
      s3c
      s4a
      s5a
      s6a
      s6b
      s6c
      s6d
      s7a
      s7b
      s8a
      year
      c1ag2
      c1ag3
      esg_rating
      esg_score
      esg_Erating
      esg_Srating
      esg_Grating
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      userid
      c1ag1
      c1b
      c1c
      c2a
      c2b
      c3ag11a
      c3ag11b
      c3ag12a
      c3ag12b
      c3ag12c
      c3ag12d
      c3ag21a
      c3ag21b
      c3ag22d
      c3ag22c
      c3ag22b
      c3ag22a
      c3b1a
      c3b1b
      c3b2a
      c3b2b
      c3b2c
      c3b2d
      c4a
      c5a
      c5b
      c5c
      c6ag1
      c6ag2
      c6b
      c6cg1
      c6cg2
      c6d
      c7a
      c8a
      c9a
      c10a
      c10a1
      c10a2
      c11a
      c11b
      c11c
      s1a
      s1b
      s1c
      s1d
      s2a
      s3a
      s3b
      s3c
      s4a
      s5a
      s6a
      s6b
      s6c
      s6d
      s7a
      s7b
      s8a
      year
      c1ag2
      c1ag3
      esg_rating
      esg_score
      esg_Erating
      esg_Srating
      esg_Grating
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      userid
      c1ag1
      c1b
      c1c
      c2a
      c2b
      c3ag11a
      c3ag11b
      c3ag12a
      c3ag12b
      c3ag12c
      c3ag12d
      c3ag21a
      c3ag21b
      c3ag22d
      c3ag22c
      c3ag22b
      c3ag22a
      c3b1a
      c3b1b
      c3b2a
      c3b2b
      c3b2c
      c3b2d
      c4a
      c5a
      c5b
      c5c
      c6ag1
      c6ag2
      c6b
      c6cg1
      c6cg2
      c6d
      c7a
      c8a
      c9a
      c10a
      c10a1
      c10a2
      c11a
      c11b
      c11c
      s1a
      s1b
      s1c
      s1d
      s2a
      s3a
      s3b
      s3c
      s4a
      s5a
      s6a
      s6b
      s6c
      s6d
      s7a
      s7b
      s8a
      year
      c1ag2
      c1ag3
      esg_rating
      esg_score
      esg_Erating
      esg_Srating
      esg_Grating
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCom = /* GraphQL */ `
  mutation CreateCom(
    $input: CreateComInput!
    $condition: ModelComConditionInput
  ) {
    createCom(input: $input, condition: $condition) {
      id
      Company
      Location
      Portfolio
      userid
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCom = /* GraphQL */ `
  mutation UpdateCom(
    $input: UpdateComInput!
    $condition: ModelComConditionInput
  ) {
    updateCom(input: $input, condition: $condition) {
      id
      Company
      Location
      Portfolio
      userid
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCom = /* GraphQL */ `
  mutation DeleteCom(
    $input: DeleteComInput!
    $condition: ModelComConditionInput
  ) {
    deleteCom(input: $input, condition: $condition) {
      id
      Company
      Location
      Portfolio
      userid
      createdAt
      updatedAt
      owner
    }
  }
`;
