<template>
  <nav class="navbar navbar-expand-lg top-0 z-index-3 justify-content-end">
    <div v-if="signedIn">
      <header v-if="windowWidth >= 1200" class="primary">
        <div class="ms-2 ms-lg-3"></div>
        <div class="routing align-center justify-start ms-4 md=6">
          <router-link to="/logout" class="nav-link me-2" style="color: white;"> Logout </router-link>
        </div>
      </header>

      <header v-else class="primary" style="height: 35px">
        <div class="esg-title">
   <img :src="logo" alt="ESG Logo" style="height:35px;" />
  </div>
        <div class="ms-3 ms-lg-3 d-flex justify-content-end">
          
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <router-link to="/logout" class="nav-link me-2" style="color: white;">Logout</router-link>
        </div>
      </header>
    </div>
  </nav>
</template>


<script>
import logo from "@/assets/img/esglogo.png";
import { Auth } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { defineComponent } from "vue";
import { mapMutations } from "vuex";

export default defineComponent({
  name: "NavBar",
  data() {
    return {
      logo,
      logoIcon: "@/assets/img/logo-ct-dark.png",
      logoName: "@/assets/img/logo-ct-dark.png",
      link: "https://www.esg.my",
      user: undefined,
      signedIn: undefined,
      authState: undefined,
      windowWidth: 0,
    };
  },

  methods: {
    ...mapMutations(["navbarMinimize"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
  },
  created() {
    onAuthUIStateChange((authState) => {
      this.authState = authState;
    });
  },
  beforeCreate() {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        this.signedIn = true;
        this.user = data;
      })
      .catch(() => {
        this.signedIn = false;
      });
  },
});
</script>

<style scoped>
.button {
  float: right;
  color: white;
  padding: 9px;
  font-size: large;
}

button:active {
  outline-width: 1;
  outline-color: white;
}

.esg-title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  display: none; /* hide the element by default */
}
.esg-title h4 {
  margin: 0;
  padding: 0;
}

@media (max-width: 1199px) {
  .esg-title {
    display: block;
    padding: 10px; /* add some padding */
  }

  .esg-title h3 {
    margin: 0;
    font-size: 1rem; /* increase the font size */
  }
}
</style>
