<template>
  <div class="py-4 container-fluid">
    <br />
    <div class="row">
      <div class="col-lg-12 mb-lg">
        <!-- line chart -->
        <div class="card z-index-2">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex align-items-center">
              <p>The customisable Portocol ESG Dashboard tracks and analyses annual data based on your corporate priorities, behaviours and activation points.Our analysis also reports on how any action may potentially have a positive impact, opening opportunities for responsible consumer-facing businesses to build loyalty. Today, our research reveals how consumers want companies to achieve this by reducing their own carbon emissions rather than resorting to “Carbon Offsetting” programs outside of their own area of business.
What is your journey?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <!-- Link for 2022 -->
          
        <div class="col-lg-3 col-md-6 col-12" style="padding-left: 20px;">
  <a href="#" @click="showRow = '2022'" :class="{ 'active': showRow === '2022'}" > 2022 </a>|
     <a href="#" @click="showRow = '2021'"  :class="{ 'active': showRow === '2021' }" > 2021 </a>|
            <a href="#" @click="showRow = '2020'" :class="{ 'active': showRow === '2020' }" > 2020</a>
</div>
        
        
          
        </div>
        <div class="row" v-if="showRow === '2022'">
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="ESG Rating 2022"
              :value="esgRating2022"
              :percentage="percentageChange2022"
              :iconClass="'ni ni-chart-bar-32'"
              iconBackground="bg-gradient-danger"
              :percentageColor="
                percentageChange2022b > 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year. ESG Score: ' + esgScore2022"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Environment Rating 2022"
              :value="esgERating2022"
              :percentage="percentageERating2022"
              :iconClass="'fas fa-leaf'"
              iconBackground="bg-gradient-success"
              :percentageColor="
                percentageERating2022b > 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year.'"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Social Rating 2022"
              :iconClass="'fas fa-users'"
              iconBackground="bg-gradient-primary"
              :value="esgSRating2022"
              :percentage="percentageSRating2022"
              :percentageColor="
                percentageSRating2022b > 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year.'"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Governance Rating 2022"
              :value="esgGRating2022"
              :iconClass="'fas fa-cogs'"
              iconBackground="bg-gradient-info"
              :percentage="percentageGRating2022"
              :percentageColor="
                percentageGRating2022b > 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year.'"
              directionReverse
            ></Card>
          </div>
        </div>

        <div class="row" v-if="showRow === '2021'">
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="ESG Rating 2021"
              :value="esgRating2021"
              :percentage="percentageChange2021"
              :iconClass="'ni ni-chart-bar-32'"
              iconBackground="bg-gradient-danger"
              :percentageColor="
                percentageChange2021b > 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year. ESG Score: ' + esgScore2021"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Environment Rating 2021"
              :value="esgERating2021"
              :percentage="percentageERating2021"
              :iconClass="'fas fa-leaf'"
              iconBackground="bg-gradient-success"
              :percentageColor="
                percentageERating2021b >= 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year.'"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Social Rating 2021"
              :iconClass="'fas fa-users'"
              iconBackground="bg-gradient-primary"
              :value="esgSRating2021"
              :percentage="percentageSRating2021"
              :percentageColor="
                percentageSRating2021b > 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year.'"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Governance Rating 2021"
              :value="esgGRating2021"
              :iconClass="'fas fa-cogs'"
              iconBackground="bg-gradient-info"
              :percentage="percentageGRating2021"
              :percentageColor="
                percentageGRating2021b > 0 ? 'text-success' : 'text-danger'
              "
              :detail="'since last year.'"
              directionReverse
            ></Card>
          </div>
        </div>

        <div class="row" v-if="showRow === '2020'">
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="ESG Rating 2020"
              :value="esgRating2020"
              :iconClass="'ni ni-chart-bar-32'"
              iconBackground="bg-gradient-danger"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Environment Rating 2020"
              :value="esgERating2020"
              :iconClass="'fas fa-leaf'"
              iconBackground="bg-gradient-success"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Social Rating 2020"
              :iconClass="'fas fa-users'"
              iconBackground="bg-gradient-primary"
              :value="esgSRating2020"
              directionReverse
            ></Card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <Card
              title="Governance Rating 2021"
              :value="esgGRating2020"
              :iconClass="'fas fa-cogs'"
              iconBackground="bg-gradient-info"
              directionReverse
            ></Card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <GradientLineChart
                :esgRating20="Number(esgRating2020)"
                :esgRating21="Number(esgRating2021)"
                :esgRating22="Number(esgRating2022)"
                :percentageCh="Number(percentageChangeallb)"
              />
            </div>
          </div>
          <div class="col-lg-3 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <div class="card" style="display: flex">
                <div class="p-3 pb-0 card-header">
                  <div class="d-flex align-items-center">
                    <h6 class="mb-0">ESG Rating breakdown %</h6>
                  </div>
                </div>

                <ESGDonut  v-if="showRow === '2022'"
                  :Year="2020"
                  chartId="chart4"
                  :esgGA="Number(esgERating2022)"
                  :esgGB="Number(esgSRating2022)"
                  :esgGC="Number(esgGRating2022)"
                ></ESGDonut>

                  <ESGDonut  v-if="showRow === '2021'"
                  :Year="2020"
                  chartId="chart4"
                  :esgGA="Number(esgERating2021)"
                  :esgGB="Number(esgSRating2021)"
                  :esgGC="Number(esgGRating2021)"
                ></ESGDonut>

                   <ESGDonut  v-if="showRow === '2020'"
                  :Year="2020"
                  chartId="chart4"
                  :esgGA="Number(esgERating2020)"
                  :esgGB="Number(esgSRating2020)"
                  :esgGC="Number(esgGRating2020)"
                ></ESGDonut>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <EsgTab></EsgTab>
  </div>

  <div></div>
</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import ESGDonut from "@/examples/Charts/ESGMainDonut.vue";
import EsgTab from "@/examples/Charts/EsgTab.vue";
import { listQuestions } from "@/graphql/queries";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { createCom } from "@/graphql/mutations";
import { listComs } from "@/graphql/queries";
import { createQuestion } from "@/graphql/mutations";

export default {
  name: "dashboard-default",
  components: {
    Card,
    GradientLineChart,
    ESGDonut,
    EsgTab,
  },

  setup() {
    const showRow = ref("2022");
    const esgRating2022 = ref("");
    const esgERating2022 = ref("");
    const esgSRating2022 = ref("");
    const esgGRating2022 = ref("");
    const esgERating2021 = ref("");
    const esgSRating2021 = ref("");
    const esgGRating2021 = ref("");
    const esgRating2021 = ref("");
    const esgRating2020 = ref("");
    const esgERating2020 = ref("");
    const esgSRating2020 = ref("");
    const esgGRating2020 = ref("");
    const esgTrain2022 = ref("");
    const esgTrain2021 = ref("");
    const esgTrain2020 = ref("");
    const esgScore2022 = ref("");
    const esgScore2021 = ref("");
    const esgScore2020 = ref("");
    const percentageERating2022 = ref("");
    const percentageERating2021 = ref("");
    const percentageSRating2022 = ref("");
    const percentageGRating2022 = ref("");
    const percentageSRating2021 = ref("");
    const percentageGRating2021 = ref("");
    const percentageERating2022b = ref("");
    const percentageERating2021b = ref("");
    const percentageSRating2022b = ref("");
    const percentageGRating2022b = ref("");
    const percentageSRating2021b = ref("");
    const percentageGRating2021b = ref("");
    const percentageChange2022 = ref("");
    const percentageChange2022b = ref("");
    const percentageChange2021 = ref("");
    const percentageChange2021b = ref("");
    const percentageChangeall = ref("");
    const percentageChangeallb = ref("");
    const esgGenderA20 = ref("");
    const esgGenderB20 = ref("");
    const esgGenderA21 = ref("");
    const esgGenderB21 = ref("");
    const esgGenderA22 = ref("");
    const esgGenderB22 = ref("");

    const router = useRouter();

    const createQuest = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const company2022 = { year: "2022", userid: userId };
        const company2021 = { year: "2021", userid: userId };
        const company2020 = { year: "2020", userid: userId };
        await API.graphql(
          graphqlOperation(createQuestion, { input: company2020 })
        );
        await API.graphql(
          graphqlOperation(createQuestion, { input: company2021 })
        );
        await API.graphql(
          graphqlOperation(createQuestion, { input: company2022 })
        );

        console.log("Questions created!");
      } catch (error) {
        console.log("Error creating questions...", error);
      }
    };

    const createCompany = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const company = {
          Company: "",
          Location: "",
          Portfolio: "12",
          userid: userId,
        };
        await API.graphql(graphqlOperation(createCom, { input: company }));
        console.log("Company created!");
      } catch (error) {
        console.log("Error creating company...", error);
      }
    };

    const showAlert = async () => {
      window.alert(`Welcome
  Below are instructions for ESG dashboard 
  1. Create Your profile
  2. Fill up ESG question from 2020-2022
  3. ESG Score will be generated`);
    };

    const fetchData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const response = await API.graphql({
          query: listQuestions,
          variables: {
            filter: {
              userid: {
                eq: userId,
              },
            },
          },

          fetchPolicy: "network-only",
        });

        const response2 = await API.graphql({
          query: listComs,
          variables: {
            filter: {
              userid: {
                eq: userId,
              },
            },
          },
          fetchPolicy: "network-only",
        });

        if (response2.data.listComs.items.length === 0) {
          await createCompany();
          await createQuest();
          await showAlert();

          // Navigate to another page if no data is fetched
          router.push({ name: "Account" });
        }

        if (response2.data.listComs.items[0].Company === "") {
          await showAlert();
        }

        const questions = response.data.listQuestions.items;

        questions.forEach((question) => {
          if (question.year === 2022) {
            esgRating2022.value = question.esg_rating;
            esgERating2022.value = question.esg_Erating.toFixed(2);
            esgSRating2022.value = question.esg_Srating.toFixed(2);
            esgGRating2022.value = question.esg_Grating.toFixed(2);
            esgGenderA22.value = question.c3b1a;
            esgGenderB22.value = question.c3b1b;
            esgScore2022.value = question.esg_score;
            esgTrain2022.value = question.c6ag1 + question.c6ag2;
          } else if (question.year === 2021) {
            esgRating2021.value = question.esg_rating;
            esgERating2021.value = question.esg_Erating.toFixed(2);
            esgSRating2021.value = question.esg_Srating.toFixed(2);
            esgGRating2021.value = question.esg_Grating.toFixed(2);
            esgGenderA21.value = question.c3b1a;
            esgGenderB21.value = question.c3b1b;

            esgScore2021.value = question.esg_score;
            esgTrain2021.value = question.c6ag1 + question.c6ag2;
          } else if (question.year === 2020) {
            esgGenderA20.value = question.c3b1a;
            esgGenderB20.value = question.c3b1b;
            esgRating2020.value = question.esg_rating;
            esgERating2020.value = question.esg_Erating.toFixed(2);
            esgSRating2020.value = question.esg_Srating.toFixed(2);
            esgGRating2020.value = question.esg_Grating.toFixed(2);
            esgScore2020.value = question.esg_score;
            esgTrain2020.value = question.c6ag1 + question.c6ag2;
          }
        });

        percentageERating2022.value =
          ((esgERating2022.value - esgERating2021.value) /
            esgERating2021.value) *
          100;
        percentageERating2022b.value = Math.round(percentageERating2022.value);
        percentageERating2022.value =
          (percentageERating2022b.value > 0 ? "+" : "") +
          percentageERating2022b.value +
          "%";

          percentageERating2021.value =
          ((esgERating2021.value - esgERating2020.value) /
            esgERating2020.value) *
          100;
        percentageERating2021b.value = Math.round(percentageERating2021.value);
        percentageERating2021.value =
          (percentageERating2021b.value > 0 ? "+" : "") +
          percentageERating2021b.value +
          "%";

            percentageSRating2021.value =
          ((esgSRating2021.value - esgSRating2020.value) /
            esgSRating2020.value) *
          100;
        percentageSRating2021b.value = Math.round(percentageSRating2021.value);
        percentageSRating2021.value =
          (percentageSRating2021b.value > 0 ? "+" : "") +
          percentageSRating2021b.value +
          "%";
              percentageGRating2021.value =
          ((esgGRating2021.value - esgGRating2020.value) /
            esgGRating2020.value) *
          100;
        percentageGRating2021b.value = Math.round(percentageGRating2021.value);
        percentageGRating2021.value =
          (percentageGRating2021b.value > 0 ? "+" : "") +
          percentageGRating2021b.value +
          "%";

        percentageSRating2022.value =
          ((esgSRating2022.value - esgSRating2021.value) /
            esgSRating2021.value) *
          100;
        percentageSRating2022b.value = Math.round(percentageSRating2022.value);
        percentageSRating2022.value =
          (percentageSRating2022b.value > 0 ? "+" : "") +
          percentageSRating2022b.value +
          "%";

          

        percentageGRating2022.value =
          ((esgGRating2022.value - esgGRating2021.value) /
            esgGRating2021.value) *
          100;
        percentageGRating2022b.value = Math.round(percentageGRating2022.value);
        percentageGRating2022.value =
          (percentageGRating2022b.value > 0 ? "+" : "") +
          percentageGRating2022b.value +
          "%";

        percentageChange2022.value =
          ((esgRating2022.value - esgRating2021.value) / esgRating2021.value) *
          100;
        percentageChange2022b.value = Math.round(percentageChange2022.value);
        percentageChange2022.value =
          (percentageChange2022b.value > 0 ? "+" : "") +
          percentageChange2022b.value +
          "%";
        percentageChange2021.value =
          ((esgRating2021.value - esgRating2020.value) / esgRating2020.value) *
          100;
        percentageChange2021b.value = Math.round(percentageChange2021.value);
        percentageChange2021.value =
          (percentageChange2021b.value > 0 ? "+" : "") +
          percentageChange2021b.value +
          "%";
        percentageChangeall.value =
          ((esgRating2022.value - esgRating2020.value) / esgRating2020.value) *
          100;
        percentageChangeallb.value = Math.round(percentageChangeall.value);
        percentageChangeall.value =
          (percentageChangeallb.value > 0 ? "+" : "") +
          percentageChangeallb.value +
          "%";
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      esgRating2022,
      esgERating2022,
      esgSRating2022,
      esgGRating2022,
      esgERating2021,
      esgSRating2021,
      esgGRating2021,
      esgRating2021,
      esgRating2020,
      esgSRating2020,
      esgGRating2020,
      esgERating2020,
      esgTrain2022,
      esgTrain2021,
      esgTrain2020,
      esgScore2022,
      esgScore2021,
      esgScore2020,
      percentageERating2022,
      percentageERating2021,
      percentageSRating2021,
      percentageGRating2021,
      percentageSRating2022,
      percentageGRating2022,
      percentageERating2022b,
      percentageSRating2022b,
      percentageGRating2022b,
      percentageSRating2021b,
      percentageGRating2021b,
      percentageChange2022,
      percentageChange2022b,
      percentageChange2021,
      percentageChange2021b,
      percentageChangeall,
      percentageChangeallb,
      esgGenderA20,
      esgGenderB20,
      esgGenderA21,
      esgGenderB21,
      esgGenderA22,
      esgGenderB22,
      showRow,
    };
  },
};
</script>

<style>
.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
}

.main-content {
  z-index: 1;
}

.col-lg-3 a.active {
  font-weight: bold;
}

@media (min-width: 1200px) {
  .sidenav {
    display: block;
  }

  .main-content {
    margin-left: 250px;
    width: calc(100% - 250px);
    z-index: 1000;
  }
}
</style>