<template>
  <div id="app" class="landing-bg h-100 position-fixed w-100">
    <sidenav v-if="state.signedIn" :custom_class="state.mcolor" />

    <main :class="mainClass">
    <navbar v-if="state.signedIn" class="bg-red left-auto top-2 z-index-sticky" />

      <router-view />
    </main>
    <app-footer v-if="state.signedIn" />
  </div>
</template>


<script>
import { Auth, Hub } from "aws-amplify";
import { reactive, onMounted, computed } from "vue";
import router from "./router";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import Navbar from "@/examples/Navbars/Navbar.vue";
import Sidenav from "@/examples/Sidenav";

import { activateDarkMode } from "@/assets/js/dark-mode";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
  
  },
  setup() {
    const state = reactive({
      signedIn: false,
      user: null,

      mcolor: "green",
    });

    onMounted(async () => {
      try {
         activateDarkMode();
        const user = await Auth.currentAuthenticatedUser();
        state.signedIn = true;
        state.user = user;
      } catch (error) {
        console.log(error);
      }
    });

    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        state.signedIn = true;
        state.user = payload.data;

        router.push({ name: "ESG Journey" });
      } else if (payload.event === "signOut") {
        state.signedIn = false;
        router.push({ name: "AuthComponent" });
      }
    });

    onAuthUIStateChange((authState, authData) => {
      if (authState === "signedin") {
        state.signedIn = true;
        state.user = authData;
        router.push({ name: "ESG Journey" });
      } else {
        state.signedIn = false;
        state.user = null;
        router.push({ name: "AuthComponent" });
      }
    });

    return {
      state,

      mainClass: computed(() =>
        state.signedIn
          ? "main-content position-relative max-height-vh-100 h-100 border-radius-lg"
          : ""
      ),
    };
  },
};
</script>




<style>
#app {
  overflow-y: auto;
  background: #030724;
 
  ;
}

body {

      padding: 0rem 0rem;
}
</style>