<template>
  <figure class="tabBlock">
    <ul class="tabBlock-tabs">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :aria-setsize="tabs.length"
        :aria-posinet="index + 1"
      >
        <a
          href="javascript:void(0);"
          class="tabBlock-tab"
          :class="active_tab === index ? 'is-active' : ''"
          :aria-selected="active_tab === index"
          @click="changeTab(index)"
        >
          {{ tab.tab_title }}
        </a>
      </li>
    </ul>
    <div class="tabBlock-content">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :aria-current="active_tab === index"
        class="tabBlock-pane"
        v-show="active_tab === index"
      >
        <p>{{ tab.tab_content }}</p>

        <div class="row" v-if="index === 2">
          <div class="col-lg-9">
            <div class="card" style="display: flex">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex align-items-center">
                  <h6 class="mb-0">Diversity</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart
                    :Year="2020"
                    chartId="chart1"
                    :esgGA="Number(esgGenderA20)"
                    :esgGB="Number(esgGenderB20)"
                  ></ConsumptionRoomChart>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart2
                    :Year="2021"
                    chartId="chart2"
                    :esgGA="Number(esgGenderA21)"
                    :esgGB="Number(esgGenderB21)"
                  ></ConsumptionRoomChart2>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart3
                    :Year="2022"
                    chartId="chart3"
                    :esgGA="Number(esgGenderA22)"
                    :esgGB="Number(esgGenderB22)"
                  ></ConsumptionRoomChart3>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12">
                  <h6 class="text-center mb-4">Director Gender Percentage</h6>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart
                    :Year="2020"
                    chartId="chart111"
                    :esgGA="Number(esgGenderA20m)"
                    :esgGB="Number(esgGenderB20m)"
                  ></ConsumptionRoomChart>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart2
                    :Year="2021"
                    chartId="chart222"
                    :esgGA="Number(esgGenderA21m)"
                    :esgGB="Number(esgGenderB21m)"
                  ></ConsumptionRoomChart2>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart3
                    :Year="2022"
                    chartId="chart333"
                    :esgGA="Number(esgGenderA22m)"
                    :esgGB="Number(esgGenderB22m)"
                  ></ConsumptionRoomChart3>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12">
                  <h6 class="text-center mb-4">Management Gender Percentage</h6>
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart
                    :Year="2020"
                    chartId="chart1111"
                    :esgGA="Number(esgGenderA20e)"
                    :esgGB="Number(esgGenderB20e)"
                  ></ConsumptionRoomChart>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart2
                    :Year="2021"
                    chartId="chart2222"
                    :esgGA="Number(esgGenderA21e)"
                    :esgGB="Number(esgGenderB21e)"
                  ></ConsumptionRoomChart2>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <ConsumptionRoomChart3
                    :Year="2022"
                    chartId="chart3333"
                    :esgGA="Number(esgGenderA22e)"
                    :esgGB="Number(esgGenderB22e)"
                  ></ConsumptionRoomChart3>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12">
                  <h6 class="text-center mb-4">Employee Gender Percentage</h6>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <br />
        <div class="row" v-if="index === 2">
          <div class="col-lg-4">
           
                  <dataprivacy
              stitle="Number of omplaints concerning privacy & customer data"
              htitle="Data privacy and security"
              chartId="datachart1"
              :esgH22="Number(esgData2022)"
              :esgH21="Number(esgData2021)"
              :esgH20="Number(esgData2020)"
            ></dataprivacy>
          </div>

          <div class="col-lg-4">
            <StakedBar
              stitle="% of employees trained on health and safety standards"
              htitle="Health and Safety"
              chartId="hchart1"
              :esgH22="Number(esgH2022)"
              :esgH21="Number(esgH2021)"
              :esgH20="Number(esgH2020)"
             
            ></StakedBar>
          </div>
          <div class="col-lg-4">
           
              <supply
               stitle="% on local suppliers"
                htitle="Supply Chain Management"
                chartId="chart21"
                 :esgH22="Number(esgSu2022)"
                :esgH21="Number(esgSu2021)"
                :esgH20="Number(esgSu2020)"
              ></supply>
            
          </div>
        </div>
        <br />
        <div class="row" v-if="index === 2">
          <div class="col-lg-6">
       <gbar2
              :esgTrain20="Number(esgTrain2020)"
              :esgTrain21="Number(esgTrain2021)"
              :esgTrain22="Number(esgTrain2022)"
              :esgTrain20m="Number(esgTrain2020m)"
              :esgTrain21m="Number(esgTrain2021m)"
              :esgTrain22m="Number(esgTrain2022m)"
            ></gbar2>
          </div>
          <div class="col-lg-6">
          <anti 
              :esgA2022e="Number(esgAnti2022e)"
              :esgA2021e="Number(esgAnti2021e)"
              :esgA2020e="Number(esgAnti2020e)"
              :esgA2021m="Number(esgAnti2021m)"
              :esgA2022m="Number(esgAnti2022m)"
              :esgA2020m="Number(esgAnti2020m)"
              :esgA2022d="Number(esgAnti2022d)"
              :esgA2021d="Number(esgAnti2021d)"
              :esgA2020d="Number(esgAnti2020d)"></anti>
          </div>
        </div>

        <div class="row" v-if="index === 1">
          <div class="col-lg-8">
            <socialchart
              :esgSA2022="Number(esgSocial2022a)"
              :esgSB2022="Number(esgSocial2022b)"
              :esgSA2021="Number(esgSocial2021a)"
              :esgSB2021="Number(esgSocial2021b)"
              :esgSA2020="Number(esgSocial2020a)"
              :esgSB2020="Number(esgSocial2020b)"
            />
          </div>
        </div>
        <div class="row" v-if="index === 0">
          <div class="col-lg-4">
            <totalwater
              stitle="Total volume of water used (m3)"
              htitle="Water Management"
              chartId="wchart1"
              :esgTrain20="Number(esgWater2020)"
              :esgTrain21="Number(esgWater2021)"
              :esgTrain22="Number(esgWater2022)"
            ></totalwater>
          </div>
          <div class="col-lg-8">
            <div class="card" style="display: flex">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex align-items-center">
                  <h6 class="mb-0">Waste Management</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <wastechart
                    :Year="2022"
                    chartId="chart11"
                    stitle="2022"
                    :esgGA="Number(esgWaste2022)"
                    :esgGB="Number(esgWaste2022b)"
                  ></wastechart>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <wastechart1
                    :Year="2021"
                    chartId="chart12"
                    stitle="2021"
                    :esgGA="Number(esgWaste2021)"
                    :esgGB="Number(esgWaste2021b)"
                  ></wastechart1>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <wastechart2
                    :Year="2022"
                    chartId="chart13"
                    stitle="2020"
                    :esgGA="Number(esgWaste2020)"
                    :esgGB="Number(esgWaste2020b)"
                  ></wastechart2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row" v-if="index === 0">
          <div class="col-lg-7">
            <groupbar
              :esgGA2022="Number(esgEmi2022a)"
              :esgGB2022="Number(esgEmi2022b)"
              :esgGC2022="Number(esgEmi2022c)"
              :esgGA2021="Number(esgEmi2021a)"
              :esgGB2021="Number(esgEmi2021b)"
              :esgGC2021="Number(esgEmi2021c)"
              :esgGA2020="Number(esgEmi2020a)"
              :esgGB2020="Number(esgEmi2020b)"
              :esgGC2020="Number(esgEmi2020c)"
            ></groupbar>
          </div>
          <div class="col-lg-5">
            <horibar
              stitle=" Total Energy Consumption (kWh)"
              htitle="Energy Management"
              chartId="hchart2"
              :esgTrain20="Number(esgEner2020)"
              :esgTrain21="Number(esgEner2021)"
              :esgTrain22="Number(esgEner2022)"
            ></horibar>
          </div>
        </div>
      </div>
      <br />

      <hr />
      <div class="tabBlock-pane">
        <h6>ESG Methodology</h6>
        <p>
          In this methodology, the ESG score represents the qualitative
          assessment of ESG performance on a scale from 1 to 5, where a higher
          score indicates better performance. The ESG rating represents the
          quantitative assessment of ESG performance on a scale from 0 to 100,
          where a higher rating indicates better performance. Please note that
          this is just an example and actual ESG methodologies may vary
          depending on the specific criteria and benchmarks used by different
          organizations or rating agencies.
        </p>
        <div class="row">
          <div class="col-lg-6">
            <h6>ESG Score Methodology</h6>
            <ul>
              <li><strong>1:</strong> Poor ESG performance</li>
              <li><strong>2:</strong> Below average ESG performance</li>
              <li><strong>3:</strong> Average ESG performance</li>
              <li><strong>4:</strong> Above average ESG performance</li>
              <li><strong>5:</strong> Excellent ESG performance</li>
            </ul>
          </div>

          <div class="col-lg-6">
            <h6>ESG Rating Methodology</h6>
            <ul>
              <li><strong>0-29:</strong> Very low ESG rating</li>
              <li><strong>30-49:</strong> Low ESG rating</li>
              <li><strong>50-69:</strong> Moderate ESG rating</li>
              <li><strong>70-89:</strong> High ESG rating</li>
              <li><strong>90-100:</strong> Very high ESG rating</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </figure>
</template>

<script>
import ConsumptionRoomChart3 from "@/examples/Charts/ConsumptionRoomChart.vue";
import ConsumptionRoomChart from "@/examples/Charts/ConsumptionRoomChart.vue";
import ConsumptionRoomChart2 from "@/examples/Charts/ConsumptionRoomChart.vue";

import groupbar from "@/examples/Charts/GroupBar.vue";
import horibar from "@/examples/Charts/Horibar.vue";
import gbar2 from "@/examples/Charts/gbarTrain.vue";

import totalwater from "@/examples/Charts/waterChart.vue";
import wastechart from "@/examples/Charts/wasteChart.vue";
import wastechart1 from "@/examples/Charts/wasteChart.vue";
import wastechart2 from "@/examples/Charts/wasteChart.vue";
import socialchart from "@/examples/Charts/socialchart.vue";
import dataprivacy from "@/examples/Charts/PrivacyChart.vue";
import supply from "@/examples/Charts/StakedBar.vue";
import StakedBar from "@/examples/Charts/StakedBar.vue";
import anti from "@/examples/Charts/antiChart.vue";
import { listQuestions } from "@/graphql/queries";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { createCom } from "@/graphql/mutations";
import { listComs } from "@/graphql/queries";
import { createQuestion } from "@/graphql/mutations";
export default {
  components: {
    ConsumptionRoomChart,
    ConsumptionRoomChart2,
    ConsumptionRoomChart3,
  
    horibar,
    groupbar,
    totalwater,
    wastechart,
    wastechart1,
    wastechart2,
    socialchart,

    supply,
    dataprivacy,
    StakedBar,
    gbar2,
    anti
  },

  setup() {
    const esgRating2022 = ref("");
    const esgRating2021 = ref("");
    const esgRating2020 = ref("");
    const esgTrain2022 = ref("");
    const esgTrain2021 = ref("");
    const esgTrain2020 = ref("");

    const esgTrain2022m = ref("");
    const esgTrain2021m = ref("");
    const esgTrain2020m = ref("");

    const esgScore2022 = ref("");
    const esgScore2021 = ref("");
    const esgScore2020 = ref("");
    const percentageChange2022 = ref("");
    const percentageChange2022b = ref("");
    const percentageChange2021 = ref("");
    const percentageChange2021b = ref("");
    const percentageChangeall = ref("");
    const percentageChangeallb = ref("");
    const esgGenderA20 = ref("");
    const esgGenderB20 = ref("");
    const esgGenderA21 = ref("");
    const esgGenderB21 = ref("");
    const esgGenderA22 = ref("");
    const esgGenderB22 = ref("");

    const esgGenderA20m = ref("");
    const esgGenderB20m = ref("");
    const esgGenderA21m = ref("");
    const esgGenderB21m = ref("");
    const esgGenderA22m = ref("");
    const esgGenderB22m = ref("");

    const esgGenderA20e = ref("");
    const esgGenderB20e = ref("");
    const esgGenderA21e = ref("");
    const esgGenderB21e = ref("");
    const esgGenderA22e = ref("");
    const esgGenderB22e = ref("");

    const esgWaste2021 = ref("");
    const esgWaste2022 = ref("");
    const esgWaste2020 = ref("");
    const esgWaste2021b = ref("");
    const esgWaste2022b = ref("");
    const esgWaste2020b = ref("");
    const esgWater2021 = ref("");
    const esgWater2022 = ref("");
    const esgWater2020 = ref("");
    const esgEner2021 = ref("");
    const esgEner2022 = ref("");
    const esgEner2020 = ref("");
    const esgEmi2021a = ref("");
    const esgEmi2022a = ref("");
    const esgEmi2020a = ref("");
    const esgEmi2021b = ref("");
    const esgEmi2022b = ref("");
    const esgEmi2020b = ref("");
    const esgEmi2021c = ref("");
    const esgEmi2022c = ref("");
    const esgEmi2020c = ref("");
    const esgSocial2022a = ref("");
    const esgSocial2022b = ref("");
    const esgSocial2021a = ref("");
    const esgSocial2021b = ref("");
    const esgSocial2020a = ref("");
    const esgSocial2020b = ref("");

    const esgH2022 = ref("");
    const esgH2021 = ref("");
    const esgH2020 = ref("");

    const esgSu2022 = ref("");
    const esgSu2021 = ref("");
    const esgSu2020 = ref("");

    const esgData2022 = ref("");
    const esgData2021 = ref("");
    const esgData2020 = ref("");

    const esgAnti2022e = ref("");
    const esgAnti2021e = ref("");
    const esgAnti2020e = ref("");

    const esgAnti2022d = ref("");
    const esgAnti2021d = ref("");
    const esgAnti2020d = ref("");

    const esgAnti2022m = ref("");
    const esgAnti2021m = ref("");
    const esgAnti2020m = ref("");

    const router = useRouter();

    const createQuest = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const company2022 = { year: "2022", userid: userId };
        const company2021 = { year: "2021", userid: userId };
        const company2020 = { year: "2020", userid: userId };
        await API.graphql(
          graphqlOperation(createQuestion, { input: company2020 })
        );
        await API.graphql(
          graphqlOperation(createQuestion, { input: company2021 })
        );
        await API.graphql(
          graphqlOperation(createQuestion, { input: company2022 })
        );

        console.log("Questions created!");
      } catch (error) {
        console.log("Error creating questions...", error);
      }
    };

    const createCompany = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const company = {
          Company: "",
          Location: "",
          Portfolio: "12",
          userid: userId,
        };
        await API.graphql(graphqlOperation(createCom, { input: company }));
        console.log("Company created!");
      } catch (error) {
        console.log("Error creating company...", error);
      }
    };

    const showAlert = async () => {
      window.alert(`Welcome
  Below are instructions for ESG dashboard 
  1. Create Your profile
  2. Fill up ESG question from 2020-2022
  3. ESG Score will be generated`);
    };

    const fetchData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const response = await API.graphql({
          query: listQuestions,
          variables: {
            filter: {
              userid: {
                eq: userId,
              },
            },
          },

          fetchPolicy: "network-only",
        });

        const response2 = await API.graphql({
          query: listComs,
          variables: {
            filter: {
              userid: {
                eq: userId,
              },
            },
          },
          fetchPolicy: "network-only",
        });

        if (response2.data.listComs.items.length === 0) {
          await createCompany();
          await createQuest();
          await showAlert();

          // Navigate to another page if no data is fetched
          router.push({ name: "Account" });
        }

        if (response2.data.listComs.items[0].Company === "") {
          await showAlert();
        }

        const questions = response.data.listQuestions.items;

        questions.forEach((question) => {
          if (question.year === 2022) {
            esgRating2022.value = question.esg_rating;
            esgGenderA22.value = question.c3b1a;
            esgGenderB22.value = question.c3b1b;
            esgScore2022.value = question.esg_score;
            esgTrain2022.value = question.c6ag1 ;
            esgTrain2022m.value = question.c6ag2;
            esgWaste2022.value = question.c10a1;
            esgWaste2022b.value = question.c10a2;
            esgWater2022.value = question.c9a;
            esgEner2022.value = question.c4a;
            esgEmi2022a.value = question.c11a;
            esgEmi2022b.value = question.c11b;
            esgEmi2022c.value = question.c11c;
            esgSocial2022a.value = question.c2a;
            esgSocial2022b.value = question.c2b;
            esgGenderB22m.value = question.c3ag21b;
            esgGenderA22m.value = question.c3ag21a;
            esgGenderB22e.value = question.c3ag11b;
            esgGenderA22e.value = question.c3ag11a;
            esgH2022.value = question.c5c;
            esgSu2022.value = question.c7a;
            esgData2022.value = question.c8a;

            esgAnti2022e.value = question.c1ag3;
            esgAnti2022m.value = question.c1ag2;
            esgAnti2022d.value = question.c1ag1;

          } else if (question.year === 2021) {
            esgRating2021.value = question.esg_rating;
            esgGenderA21.value = question.c3b1a;
            esgGenderB21.value = question.c3b1b;

            esgScore2021.value = question.esg_score;
            esgTrain2021.value = question.c6ag1 ;
            esgTrain2021m.value = question.c6ag2;
            esgWaste2021.value = question.c10a1;
            esgWaste2021b.value = question.c10a2;
            esgWater2021.value = question.c9a;
            esgEner2021.value = question.c4a;
            esgEmi2021a.value = question.c11a;
            esgEmi2021b.value = question.c11b;
            esgEmi2021c.value = question.c11c;
            esgSocial2021a.value = question.c2a;
            esgSocial2021b.value = question.c2b;
            esgGenderB21m.value = question.c3ag21b;
            esgGenderA21m.value = question.c3ag21a;
            esgGenderB21e.value = question.c3ag11b;
            esgGenderA21e.value = question.c3ag11a;
            esgH2021.value = question.c5c;
            esgSu2021.value = question.c7a;
            esgData2021.value = question.c8a;

              esgAnti2021e.value = question.c1ag3;
            esgAnti2021m.value = question.c1ag2;
            esgAnti2021d.value = question.c1ag1;
          } else if (question.year === 2020) {
            esgGenderA20.value = question.c3b1a;
            esgGenderB20.value = question.c3b1b;
            esgRating2020.value = question.esg_rating;
            esgScore2020.value = question.esg_score;
            esgTrain2020.value = question.c6ag1 ;
             esgTrain2020m.value = question.c6ag2;
            esgWaste2020.value = question.c10a1;
            esgWaste2020b.value = question.c10a2;
            esgWater2020.value = question.c9a;
            esgEner2020.value = question.c4a;
            esgEmi2020a.value = question.c11a;
            esgEmi2020b.value = question.c11b;
            esgEmi2020c.value = question.c11c;
            esgSocial2020a.value = question.c2a;
            esgSocial2020b.value = question.c2b;

            esgGenderB20m.value = question.c3ag21b;
            esgGenderA20m.value = question.c3ag21a;
            esgGenderB20e.value = question.c3ag11b;
            esgGenderA20e.value = question.c3ag11a;
            esgH2020.value = question.c5c;
            esgSu2020.value = question.c7a;
            esgData2020.value = question.c8a;
              esgAnti2020e.value = question.c1ag3;
            esgAnti2020m.value = question.c1ag2;
            esgAnti2020d.value = question.c1ag1;
          }
        });

        percentageChange2022.value =
          ((esgRating2022.value - esgRating2021.value) / esgRating2021.value) *
          100;
        percentageChange2022b.value = Math.round(percentageChange2022.value);
        percentageChange2022.value =
          (percentageChange2022b.value > 0 ? "+" : "") +
          percentageChange2022b.value +
          "%";
        percentageChange2021.value =
          ((esgRating2021.value - esgRating2020.value) / esgRating2020.value) *
          100;
        percentageChange2021b.value = Math.round(percentageChange2021.value);
        percentageChange2021.value =
          (percentageChange2021b.value > 0 ? "+" : "") +
          percentageChange2021b.value +
          "%";
        percentageChangeall.value =
          ((esgRating2022.value - esgRating2020.value) / esgRating2020.value) *
          100;
        percentageChangeallb.value = Math.round(percentageChangeall.value);
        percentageChangeall.value =
          (percentageChangeallb.value > 0 ? "+" : "") +
          percentageChangeallb.value +
          "%";
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      esgRating2022,
      esgRating2021,
      esgRating2020,
      esgTrain2022,
      esgTrain2021,
      esgTrain2020,
      esgTrain2022m,
      esgTrain2021m,
      esgTrain2020m,
      esgScore2022,
      esgScore2021,
      esgScore2020,
      percentageChange2022,
      percentageChange2022b,
      percentageChange2021,
      percentageChange2021b,
      percentageChangeall,
      percentageChangeallb,
      esgGenderA20,
      esgGenderB20,
      esgGenderA21,
      esgGenderB21,
      esgGenderA22,
      esgGenderB22,
      esgWaste2020,
      esgWaste2021,
      esgWaste2022,
      esgWaste2020b,
      esgWaste2021b,
      esgWaste2022b,
      esgWater2022,
      esgWater2021,
      esgWater2020,
      esgEner2022,
      esgEner2021,
      esgEner2020,
      esgEmi2021a,
      esgEmi2022a,
      esgEmi2020a,
      esgEmi2021b,
      esgEmi2022b,
      esgEmi2020b,
      esgEmi2021c,
      esgEmi2022c,
      esgEmi2020c,
      esgSocial2022a,
      esgSocial2022b,
      esgSocial2021a,
      esgSocial2021b,
      esgSocial2020a,
      esgSocial2020b,
      esgGenderA20m,
      esgGenderB20m,
      esgGenderA21m,
      esgGenderB21m,
      esgGenderA22m,
      esgGenderB22m,

      esgGenderA20e,
      esgGenderB20e,
      esgGenderA21e,
      esgGenderB21e,
      esgGenderA22e,
      esgGenderB22e,
      esgH2022,
      esgH2021,
      esgH2020,
      esgSu2022,
      esgSu2021,
      esgSu2020,
      esgData2022,
      esgData2021,
      esgData2020,
      esgAnti2020d,
      esgAnti2022d,
      esgAnti2021d,
      esgAnti2020m,
      esgAnti2021m,
      esgAnti2022m,
      esgAnti2020e,
      esgAnti2021e,
      esgAnti2022e,
    };
  },
  data() {
    return {
      active_tab: 0,
      tabs: [
        {
          tab_title: "Environment",
          tab_content:
            "Common sustainability measures for the environment include water conservation, waste reduction, emissions reduction, and energy efficiency.",
        },
        {
          tab_title: "Social",
          tab_content:
            " Common sustainability measures for the social aspect include community impact and societal impact. Community impact measures the positive contributions made to local communities, while societal impact assesses the broader benefits generated for society as a whole. Metrics such as total investments and total beneficiaries can be used to quantify the financial investments made and the number of people benefiting from these initiatives.",
        },
        {
          tab_title: "Governance",
          tab_content:
            "Governance sustainability measures focus on diversity, health and safety, labor practices, supply chain management, data privacy, and anti-corruption efforts. These measures assess the commitment to ethical practices, employee well-being, responsible sourcing, data protection, and transparency, contributing to sustainable and responsible governance.",
        },
      ],
    };
  },
  methods: {
    changeTab(tabIndexValue) {
      this.active_tab = tabIndexValue;
    },
  },
};
</script>

<style lang="scss">
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  color: #222;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 50rem;
  padding: 2.5rem 1.25rem;
}

/* ===================================================== */

$primary: #df4a43;
$border: #051139;

.tabBlock {
  margin: 0rem 0 2.5rem;

  &-tabs {
    list-style: none;
    margin: 0;
    padding: 0;

    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }

  &-tab {
    min-width: 70px;
    background-color: #030724;
    border-left: solid;
    border-top: solid;
    border-bottom: solid;
    border-width: 2px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    float: left;
    padding: 0.625rem 0.25rem;
    position: relative;
    transition: 0.1s ease-in-out;
    text-decoration: none;

    &:last-of-type {
      border-right-style: solid;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      height: 4px;
      position: absolute;
      transition: 0.1s ease-in-out;
    }

    &::before {
      left: -2px;
      right: -2px;
      top: -3px;
    }

    &::after {
      border-radius: 10px;
      bottom: -3px;
      left: 10px;
      right: 10px;
    }

    &:hover,
    &:focus {
      color: $primary;
    }

    @media screen and (min-width: 700px) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    &.is-active {
      position: relative;
      color: $primary;
      border-radius: 10px;
      z-index: 1;

      &::before {
      }

      &::after {
        background-color: #df4a43;
        border-radius: 5px;
      }
    }
  }

  &-content {
    background-color: #030724;
    border: 8px solid #051139;
    padding: 1.25rem;
    border-radius: 20px;

    a {
      color: dodgerblue;
      font-weight: 700;
      transition: color 200ms ease;

      &:hover,
      &:focus {
        color: orangered;
      }
    }

    hr {
      margin: 3rem 0;
      border: 1px solid transparent;
      border-radius: 50%;
      border-top-color: $border;
    }
  }
}
</style>