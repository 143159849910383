<template>
  <div>
    <div class="form-group">
      <label class="form-control-label" for="awardD">Award</label>
      <input type="text" id="awardD" v-model="awardD" @input="displayAwardD()" />
    </div>
    <div>Current Award: {{ displayFormAward }}</div>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';

export default {
  setup() {
    const form = reactive({
      Award: ''
    });
    const awardD = ref('dddd');

    function displayAwardD() {
      form.Award = awardD.value;
    }

    const displayFormAward = computed(() => {
      return form.Award || '';
    });

    return {
      form,
      awardD,
      displayAwardD,
      displayFormAward
    }
  }
}
</script>
