<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Company Profile</p>
            </div>
          </div>
           
          <div class="card-body">
            <form @submit.prevent="updateCompany">
              <div class="form-group">
                <label class="form-control-label" for="company">Company:</label>
                <argon-input type="text" id="company" :value="form.Company || ''" @input="form.Company = $event.target.value" />
              </div>

              <div class="form-group">
                <label class="form-control-label" for="location">Location:</label>
                <argon-input type="text" id="location" :value="form.Location || ''" @input="form.Location = $event.target.value" />
              </div>

              <div class="form-group">
                <label class="form-control-label" for="portfolio">Portfolio:</label>
                <select class="form-control" id="portfolio" v-model="form.Portfolio">
                  <option value="">Please select an option</option>
          
                  <option value="9">Health Care</option>
             
                   <option value="12">Financial Services</option>
                </select>
              </div>

              <div class="text-center">
                <argon-button type="submit" color="primary" class="my-4">Update</argon-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateCom } from "@/graphql/mutations";
import { listComs } from "@/graphql/queries";
import { API, graphqlOperation, Auth } from "aws-amplify";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";



export default {
  name: "app",
  components: { ArgonInput, ArgonButton },
  
  methods: {
    
    async updateCompany() {
      const { Company, Location, Portfolio } = this.form;
      if (!Location || !Portfolio) {
        return;
      }
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
     
           const response = await API.graphql({
             variables: { 
        filter: {
          userid: {
            eq: userId
          }
        }
      },
      query: listComs,
      
      fetchPolicy: 'network-only',
    }); 
        const companyId = response.data.listComs.items[0].id;
        const companyToUpdate = {
          id: companyId,
          Company,
          Location,
          Portfolio: parseInt(Portfolio),
        };
        
         const updateResponse = await API.graphql(
      graphqlOperation(updateCom, { input: companyToUpdate })
    );
    console.log("Company updated:", updateResponse);
    alert("Profile Updated")
    const updatedCompany = updateResponse.data.updateCom;
    this.form = {
      Company: updatedCompany.Company,
      Location: updatedCompany.Location,
      Portfolio: updatedCompany.Portfolio.toString(),
    };
      } catch (error) {
        console.log("Error updating company:", error);
      }
    },
  },
  async created() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;
      const response = await API.graphql({
      query: listComs,
      variables: { 
        filter: {
          userid: {
            eq: userId
          }
        }
      },
      fetchPolicy: 'network-only',
    });

      if (response.data.listComs.items.length > 0) {
        const company = response.data.listComs.items[0];
        this.form = {
          Company: company.Company,
          Location: company.Location,
          Portfolio: company.Portfolio.toString(),
        };
      }
    } catch (error) {
      console.log("Error fetching companies for user:", error);
    }
  },
  data() {
    return {
     
      form: {
        Company: null,
        Location: null,
        Portfolio: "",
      },
    };
  },
};
</script>
