<template>
  <aside class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl" id="sidenav-main">
    <div class="sidenav-header">
 
     <router-link class="m-0 navbar-brand" style="max-height: 2.5rem;!important" to="/dashboard">
   <img :src="logo" alt="ESG Logo"  style="max-height: 2rem;!important"/>
  
</router-link>
    </div>
    <hr class="mt-0 horizontal dark" />

    <!-- example sidenav items -->
    <sidenav-list :card-bg="'bg-gradient-primary'">
      <sidenav-item url="/dashboard" :nav-text="'ESG Journey'">
        <template v-slot:icon><i class="fas fa-tachometer-alt"></i></template>
        <template v-slot:text>ESG Journey</template>
      </sidenav-item>
      <sidenav-item url="/settings" :nav-text="'Settings'">
        <template v-slot:icon><i class="fas fa-cog"></i></template>
      </sidenav-item>
      <sidenav-item url="/profile" :nav-text="'Profile'">
        <template v-slot:icon><i class="fas fa-user"></i></template>
      </sidenav-item>
    </sidenav-list>

    <!-- end example sidenav items -->
  </aside>
</template>

<script>

import logo from "@/assets/img/esglogo.png";
import logoWhite from "@/assets/img/logo-ct.png";
import SidenavList from "@/examples/Sidenav/SidenavList.vue";
import SidenavItem from "@/examples/Sidenav/SidenavItem.vue";

export default {
  name: "Sidenav",
  components: {
    SidenavList,
    SidenavItem,
  },
  data() {
    return {
      logo,
      logoWhite
    };
  },
  props: ["custom_class", "layout"]
};
</script>

<style>
.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  
 
}
</style>
