<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Questions</p>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="createQuestion">
              <div class="form-group">
                <label class="form-control-label" for="year-select"
                  >Year:</label
                >
                <select
                  class="form-control"
                  id="year-select"
                  v-model="form.year"
                >
                  <option value="">Select a year</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                </select>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="question1"
                  >What is the percentages of employees who have received
                  training on anti-corruption by employee category?</label
                >
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-control-label" for="c1a"
                      >Director %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c1a"
                      :value="form.c1a"
                      @input="form.c1a = $event.target.value"
                      placeholder="Enter a percentage (0-100)"
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="form-control-label" for="c1ag2"
                      >Management %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c1ag2"
                      :value="form.c1ag2"
                      @input="form.c1ag2 = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label" for="c1ag3"
                      >Employee %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c1ag3"
                      :value="form.c1ag3"
                      @input="form.c1ag3 = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c1b"
                  >Percentage of operations assessed for corruption-related
                  risks</label
                >
                <argon-input
                  type="text"
                  id="c1b"
                  :value="form.c1b"
                  @input="form.c1b = $event.target.value"
                />
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c1b"
                  >Confirmed incidents of corruption and action taken</label
                >
                <select class="form-control" id="c1c" v-model="form.c1c">
                  <option value="">Select an option</option>
                  <option value="0">None</option>
                  <option value="25">Few incidents with no action taken</option>
                  <option value="50">
                    Some incidents with some action taken
                  </option>
                  <option value="75">
                    Several incidents with appropriate action taken
                  </option>
                  <option value="100">
                    Many incidents with strong and appropriate action taken
                  </option>
                </select>
              </div>

              <div class="text-center">
                <argon-button type="submit" color="primary" class="my-4"
                  >Create</argon-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createQuestion } from "@/graphql/mutations";
import { API, graphqlOperation, Auth } from "aws-amplify";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "app",
  components: { ArgonInput, ArgonButton },
  data() {
    return {
      form: { c1ag2: "", year: "", c1a: "", c1ag3: "", c1b: "", c1c: "" },
      createCompanyResult: null,
      companies: [],
      percentageError: null,
      formErrors: { c1a: null },
    };
  },

  watch: {
    form: {
      handler(newForm) {
        const fieldsToWatch = ["c1a", "c1ag3", "c1b", "c1ag2"];
        fieldsToWatch.forEach((field) => {
          if (newForm[field] < 0 || newForm[field] > 100) {
            this.formErrors[field] = "Please enter a value between 0 and 100.";
            this.form[field] = "";
          } else {
            this.formErrors[field] = null;
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    async createQuestion() {
      const { year, c1a, c1ag2, c1ag3, c1b, c1c } = this.form;
      if (!c1ag2 || !year || !c1a) return;

      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const company = { year, c1a, c1ag2, c1ag3, c1b, c1c, userid: userId };
        const response = await API.graphql(
          graphqlOperation(createQuestion, { input: company })
        );
        console.log("Company created!");
        this.companies = [...this.companies, response.data.createCom];
      } catch (error) {
        console.log("Error creating company...", error);
      }
    },
    validatePercentage(value) {
      const parsedValue = parseFloat(value);
      if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 100) {
        return "Please enter a valid percentage (0-100)";
      }
      return null;
    },
  },
};
</script>
