<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <h6>{{htitle}}</h6>
      <div class="pt-3 chart">
        <canvas :id="chartId" class="chart-canvas" height="490"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { reactive, watch, onMounted, onUnmounted } from 'vue';

export default {
  name: "horibar",
  props: {
     chartId: {
      type: String,
      required: true,
    },
    esgTrain20: {
      type: Number,
    },
    esgTrain21: {
      type: Number,
    },
    esgTrain22: {
      type: Number,
    },
    htitle: {
       type: String,
    },
     stitle: {
       type: String,
    },
  },
 
  setup(props) {
    const chartData = reactive({
      esgTrain20: props.esgTrain20,
      esgTrain21: props.esgTrain21,
      esgTrain22: props.esgTrain22,
    });

    let chartInstance = null;

    const updateChart1 = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = Object.values(chartData);
        chartInstance.update();
      }
    };

    watch(
      () => [props.esgTrain20, props.esgTrain21, props.esgTrain22],
      function () {
        chartData.esgTrain20 = props.esgTrain20;
        chartData.esgTrain21 = props.esgTrain21;
        chartData.esgTrain22 = props.esgTrain22;
        updateChart1();
      }
    );

    const createChart = () => {
      
const canvas = document.getElementById(props.chartId);
if (canvas) {
  const ctx = canvas.getContext('2d');
 
      chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["2020", "2021", "2022"],
          datasets: [
            {
              label: "kWh",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: ["#5e72e4", "#fb6340", "#11cdef"],
               data: [2478,5267,734],
              maxBarThickness: 12,
            },
          ],
        },

      
        options: {
           indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
             title: {
                display: true,
                text: props.stitle,
                color: "#ffffff",
              },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
             
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                  color: "#ffffff",
              },
               color: "#ffffff",
                title: {
                  display: true, // Set to true to display the x-axis title
                  text: "Years",
                  color: "#ffffff",
                  font: {
                    size: 9, // Set the font size for the x-axis title
                  },
                },
            },
            x: {
               
              grid: {
                 drawBorder: true,
                  borderColor: "#ffffff",
                  borderWidth: 0.3,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 12,
                  family: "Open Sans",
                  style: "normal",
                },
                color: "#ffffff",
              },
            },
          },
        },
      });
    };}
    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return {
      chartData,
      updateChart1,
    };
  },
};
</script>

<style scoped>
.chart {
  height: 270px;
}
</style>
