<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
   
    
      <li class="nav-item">
        <sidenav-item
          url="/dashboard"
          :navText="'ESG Journey '"
          :active="getRoute() === 'dashboard-default'"
        >  
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
          <template v-slot:text> ESG Journey </template>

          <!-- Sub-items -->
   
        </sidenav-item>
      </li>

   

      <li class="nav-item">
        <sidenav-item
          url="/Measures"
          :navText="'Measures & Analytics'"
          :active="getRoute() === 'Questions'"
        >
          <template v-slot:icon>
            <i class="ni ni-support-16 text-dark text-sm opacity-10"></i>
          </template>
          <template v-slot:text> Measure & Analytics</template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          url="/KeyHighlights"
          :navText="'Key Highlights'"
          :active="getRoute() === 'pdf'"
        >
          <template v-slot:icon>
         <i class="ni ni-trophy text-dark text-sm opacity-10"></i>
          </template>
          <template v-slot:text> Key Highlights</template>
        </sidenav-item>
      </li>

           <li class="nav-item">
        <sidenav-item
          url="/ESG-Wizard"
          :navText="'ESG Wizard'"
          :active="getRoute() === 'pdf'"
        >
          <template v-slot:icon>
            <i class="ni ni-cloud-download-95 text-dark text-sm opacity-10"></i>
          </template>
          <template v-slot:text> Key Highlights</template>
        </sidenav-item>
      </li>

         <li class="nav-item">
        <sidenav-item
          url="/profile"
          :navText="'Company Profile'"
          :active="getRoute() === 'profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
          <template v-slot:text> Profile</template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavItem,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>