<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <h6>Emissions Management</h6>
      <div class="pt-3 chart">
        <canvas id="gbar" class="chart-canvas" height="490"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { reactive, watch, onMounted, onUnmounted } from "vue";

export default {
  name: "groupbar",
  props: {
    esgGA2022: {
      type: Number,
    },
    esgGB2022: {
      type: Number,
    },
    esgGC2022: {
      type: Number,
    },
    esgGA2021: {
      type: Number,
    },
    esgGB2021: {
      type: Number,
    },
    esgGC2021: {
      type: Number,
    },
    esgGA2020: {
      type: Number,
    },
    esgGB2020: {
      type: Number,
    },
    esgGC2020: {
      type: Number,
    },
  },

  setup(props) {
    const chartData = reactive({
      esgGA2022: props.esgGA2022,
      esgGB2022: props.esgGB2022,
      esgGC2022: props.esgGC2022,
      esgGA2021: props.esgGA2021,
      esgGB2021: props.esgGB2021,
      esgGC2021: props.esgGC2021,
      esgGA2020: props.esgGA2020,
      esgGB2020: props.esgGB2020,
      esgGC2020: props.esgGC2020,
    });

    let chartInstance = null;

  const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = [props.esgGA2020, props.esgGB2020, props.esgGC2020];
        chartInstance.data.datasets[1].data = [props.esgGA2021, props.esgGB2021, props.esgGC2021];
        chartInstance.data.datasets[2].data = [props.esgGA2022, props.esgGB2022, props.esgGC2022];
        chartInstance.update();
      }
    };

    watch(
      () => [
        props.esgGA2022,
        props.esgGA2021,
        props.esgGA2020,
        props.esgGB2022,
        props.esgGB2021,
        props.esgGB2020,
        props.esgGC2022,
        props.esgGC2021,
        props.esgGC2020,
      ],
      function () {
        chartData.esgGA2022 = props.esgGA2022;
        chartData.esgGA2021 = props.esgGA2021;
        chartData.esgGA2020 = props.esgGA2020;
        chartData.esgGB2022 = props.esgGB2022;
        chartData.esgGB2021 = props.esgGB2021;
        chartData.esgGB2020 = props.esgGB2020;
        chartData.esgGC2022 = props.esgGC2022;
        chartData.esgGC2021 = props.esgGC2021;
        chartData.esgGC2020 = props.esgGC2020;
        updateChart();
      },
      { immediate: true }
    );

    const createChart = () => {
      const canvas = document.getElementById("gbar");
      if (canvas) {
        const ctx = canvas.getContext("2d");

        chartInstance = new Chart(ctx, {
          type: "bar",
          data: {
            labels: ["2020", "2021", "2022"],
            datasets: [
              {
                label: "Scope1",
                backgroundColor: "#5e72e4",
                data: [props.esgGA2020, props.esgGB2020, props.esgGC2020],
                maxBarThickness: 15,
              },
              {
                label: "Scope2",
                backgroundColor: "#fb6340",
                data: [props.esgGA2021, props.esgGB2021, props.esgGC2021],
                maxBarThickness: 15,
              },
              {
                label: "Scope3",
                backgroundColor: "#11cdef",
                data: [props.esgGA2022, props.esgGB2022, props.esgGC2022],
                maxBarThickness: 15,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "bottom", // Adjust the legend position if needed
                padding: 20,
                labels: {
                  color: "#ffffff", // Change the legend font color
                },
              },
              title: {
                display: true,
                text: "Scope 1 to 3 emission in tonnes of CO2e",
                color: "#ffffff",
              },
            },
            scales: {
              x: {
                grid: {
                  drawBorder: true,
                  borderColor: "#ffffff",
                  borderWidth: 0.3,
                  display: true,
                  drawOnChartArea: false,
                  drawTicks: true,
                },
                ticks: {
                  display: true,
                  color: "#fbfbfb",
                },
              },
              y: {
                ticks: {
                  display: true,
                  color: "#fbfbfb",
                },
                color: "#ffffff",
                title: {
                  display: true, // Set to true to display the x-axis title
                  text: "Emission in Tonnes pf CO2e",
                  color: "#ffffff",
                  font: {
                    size: 9, // Set the font size for the x-axis title
                  },
                },
              },
            },
          },
        });
      }
    };

    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return {
      chartData,
      updateChart,
    };
  },
};
</script>

<style scoped>
.chart {
  height: 270px;
}
</style>
