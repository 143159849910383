<template>
 
    <div class=" card-body" style="height: 150px;">
      <div class="text-center col-12">
        <div class="chart " style="height: 140px;">
          <canvas :id="chartId" class="chart-canvas" ></canvas>
        </div>
        <h4 class="mt-n7" style="color: white;">
          <span class="text-s d-block " style="color: white;">{{ Year }} </span>
          <span class="text-xxs d-block " style="color: white;">Women {{ esgGB }} %</span>
          <span class="text-xxs d-block " style="color: white;">Men {{ esgGA }} %</span>
        </h4>
      </div>
    </div>
  
</template>
<script>
import Chart from "chart.js/auto";
import { reactive, watch, onMounted, onUnmounted } from 'vue';

export default {
  name: "consumption-by-room-chart",
  props: {
    chartId: {
      type: String,
      required: true,
    },
    esgGA: {
      type: Number,
    },
    esgGB: {
      type: Number,
    },
     Year: {
      type: Number,
    },
  },

  setup(props) {
    const chartLine = reactive({
      esgGA: props.esgGA,
      esgGB: props.esgGB,
    });

    let chartInstance = null;

    const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = Object.values(chartLine);
        chartInstance.update();
      }
    };

    watch(
      () => [props.esgGA, props.esgGB],
      function () {
        chartLine.esgGA = props.esgGA;
        chartLine.esgGB = props.esgGB;
        updateChart();
      }
    );

    const createChart = () => {
       const canvas = document.getElementById(props.chartId);
      if (canvas) {
        const ctx = canvas.getContext('2d');

        var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
        gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
        gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors

        chartInstance = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: ["Men", "Women"],
            datasets: [
              {
                label: "Consumption",
                weight: 1.0,
                cutout: 66,
                tension: 0,
                pointRadius: 0.2,
                borderWidth: 0,
                backgroundColor: [
                  "#11cdef",
                  "#df4a43",
                ],

                data: Object.values(chartLine),
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        });
      }
    };

    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return {
      chartLine,
      updateChart,
    };
  },
};
</script>
