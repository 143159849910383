<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <h6>Anti-corruption</h6>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="anti" class="chart-canvas" height="250"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, watch } from "vue";
import Chart from "chart.js/auto";

export default {
  name: "GradientLineChart",
  props: {
    esgA2022m: {
      type: Number,
    },

    esgA2021m: {
      type: Number,
    },

    esgA2020m: {
      type: Number,
    },
    esgA2022d: {
      type: Number,
    },

    esgA2021d: {
      type: Number,
    },

    esgA2020d: {
      type: Number,
    },
    esgA2022e: {
      type: Number,
    },

    esgA2021e: {
      type: Number,
    },

    esgA2020e: {
      type: Number,
    },
  },

  setup(props) {
    const chartLine = reactive({
      esgA2022m: props.esgA2022m,
      esgA2022d: props.esgA2022d,
      esgA2021m: props.esgA2021m,
      esgA2021d: props.esgA2021d,
      esgA2020m: props.esgA2020m,
      esgA2020d: props.esgA2020d,
      esgA2022e: props.esgA2022e,
      esgA2021e: props.esgA2021e,
      esgA2020e: props.esgA2020e,
    });
    let chartInstance = null;
    const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = [
          props.esgA2020m,
          props.esgA2021m,
          props.esgA2022m,
        ];
        chartInstance.data.datasets[1].data = [
          props.esgA2020d,
          props.esgA2021d,
          props.esgA2022d,
        ];
        chartInstance.data.datasets[2].data = [
          props.esgA2020e,
          props.esgA2021e,
          props.esgA2022e,
        ];
        chartInstance.update();
      }
    };

    watch(
      () => [
        props.esgA2020m,
        props.esgA2021m,
        props.esgA2022m,
        props.esgA2020d,
        props.esgA2021d,
        props.esgA2022d,
        props.esgA2020e,
        props.esgA2021e,
        props.esgA2022e,
      ],
      function () {
        updateChart();
      },
      { immediate: true }
    );

    const createChart = () => {
      const canvas = document.getElementById("anti");
      if (canvas) {
        const ctx = canvas.getContext("2d");

        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(94, 114, 228, 0.2)");
        gradientStroke.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
        gradientStroke.addColorStop(0, "rgba(94, 114, 228, 0)");

        chartInstance = new Chart(ctx, {
          type: "line",
          data: {
            labels: ["2020", "2021", "2022"],
            datasets: [
              {
                label: "Management",
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 0,
                borderColor: "#11cdef",
                backgroundColor: gradientStroke,
                fill: true,
                data: [props.esgA2020e, props.esgA2021e, props.esgA2022e], // Sample data for ESG Rating
              },
              {
                label: "Director",
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 0,
                borderColor: "#fb6340",
                backgroundColor: gradientStroke,
                fill: true,
                data: [props.esgA2020m, props.esgA2021m, props.esgA2022m], // Sample data for ESG Rating
              },
              {
                label: "Employee",
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 0,
                borderColor: "#5e72e4",
                backgroundColor: gradientStroke,
                fill: true,
                data: [props.esgA2020d, props.esgA2021d, props.esgA2022d], // Sample data for ESG Rating
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "% of employees who have received training on anti-corruption by employee category",
                color: "#ffffff",
              },
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: true,

                  display: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                },
                ticks: {
                  display: true,
                  color: "#ffffff",
                },
                color: "#ffffff",
                title: {
                  display: true, // Set to true to display the x-axis title
                  text: "% employee",
                  color: "#ffffff",
                  font: {
                    size: 9, // Set the font size for the x-axis title
                  },
                },
              },

              x: {
                grid: {
                  drawBorder: true,
                  borderColor: "#ffffff",
                  borderWidth: 0.3,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#fbfbfb",
                  padding: 20,
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        });
      }
    };

    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return { chartLine, updateChart };
  },
};
</script>