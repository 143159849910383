<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Measures & Analytics
</h6>
            <br>
             <p>Our ESG metrics are defined and detailed analysis are submitted to track our performance toward our ESG targets. More importantly, our ESG metrics are customisable to ensure we can meet the reporting requirements of most regulatory bodies, such as the Global Reporting Initiative (GRI), Sustainability Accounting Standards Board (SASB), and Taskforce on Climate-Related Financial Disclosures (TCFD).</p>
          </div>
         
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Years
                    </th>
                    <th class="text-secondary opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(question, index) in questions" :key="index">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ question.year }}</h6>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <router-link
                        :to="{
                          name: 'edit-question',
                          params: { id: question.id },
                        }"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit question"
                      >
                        Review
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listQuestions } from "@/graphql/queries";
import { API, Auth } from "aws-amplify";

export default {
  name: "Questions",
  data() {
    return {
      questions: [],
    };
  },
  async created() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;
     const response = await API.graphql({
             variables: { 
        filter: {
          userid: {
            eq: userId
          }
        }
      },
      query: listQuestions,
      
      fetchPolicy: 'network-only',
    }); 
      this.questions = response.data.listQuestions.items;
    } catch (error) {
      console.log("Error fetching questions for user:", error);
    }
  },
};
</script>