<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <h6>Labour practices and standards</h6>
      <div class="pt-3 chart">
        <canvas id="gbar2" class="chart-canvas" height="390"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { reactive, watch, onMounted, onUnmounted } from "vue";

export default {
  name: "groupbar2",
  props: {
    esgTrain20: {
      type: Number,
    },
    esgTrain21: {
      type: Number,
    },
    esgTrain22: {
      type: Number,
    },
    esgTrain20m: {
      type: Number,
    },
    esgTrain21m: {
      type: Number,
    },
    esgTrain22m: {
      type: Number,
    },
  },

  setup(props) {
    const chartData = reactive({
      esgTrain20: props.esgTrain20,
      esgTrain21: props.esgTrain21,
      esgTrain22: props.esgTrain22,
      esgTrain20m: props.esgTrain20m,
      esgTrain21m: props.esgTrain21m,
      esgTrain22m: props.esgTrain22m,
    });

    let chartInstance = null;

    const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = [
          props.esgTrain20,
          props.esgTrain21,
          props.esgTrain22,
        ];
        chartInstance.data.datasets[1].data = [
          props.esgTrain20m,
          props.esgTrain21m,
          props.esgTrain22m,
        ];
        chartInstance.update();
      }
    };

    watch(
      () => [
        props.esgTrain20,
        props.esgTrain21,
        props.esgTrain22,
        props.esgTrain20m,
        props.esgTrain21m,
        props.esgTrain22m,
      ],
      function () {
        chartData.esgTrain20 = props.esgTrain20;
        chartData.esgTrain21 = props.esgTrain21;
        chartData.esgTrain22 = props.esgTrain22;
        chartData.esgTrain20m = props.esgTrain20m;
        chartData.esgTrain21m = props.esgTrain21m;
        chartData.esgTrain22m = props.esgTrain22m;
        updateChart();
      },
      { immediate: true }
    );

    const createChart = () => {
      const canvas = document.getElementById("gbar2");
      if (canvas) {
        const ctx = canvas.getContext("2d");

        chartInstance = new Chart(ctx, {
          type: "bar",
          data: {
            labels: ["2020", "2021", "2022"],
            datasets: [
              {
                label: "Employee Total hours of training",
                backgroundColor: "#5e72e4",
                data: [props.esgTrain20, props.esgTrain21, props.esgTrain22],
                maxBarThickness: 15,
              },
              {
                label: "Management Total hours of training",
                backgroundColor: "#fb6340",
                data: [props.esgTrain20m, props.esgTrain21m, props.esgTrain22m],
                maxBarThickness: 15,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "bottom", // Adjust the legend position if needed
                padding: 20,
                labels: {
                  color: "#ffffff", // Change the legend font color
                },
              },
              title: {
                display: true,
                text: "Total hours of training for employee and management",
                color: "#ffffff",
              },
            },
            scales: {
              x: {
                grid: {
                  drawBorder: true,
                  borderColor: "#ffffff",
                  borderWidth: 0.3,
                  display: true,
                  drawOnChartArea: false,
                  drawTicks: true,
                },
                ticks: {
                  display: true,
                  color: "#fbfbfb",
                },
              },
              y: {
                ticks: {
                  display: true,
                  color: "#fbfbfb",
                },
                color: "#ffffff",
                title: {
                  display: true, // Set to true to display the x-axis title
                  text: "Total hours",
                  color: "#ffffff",
                  font: {
                    size: 9, // Set the font size for the x-axis title
                  },
                },
              },
            },
          },
        });
      }
    };

    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return {
      chartData,
      updateChart,
    };
  },
};
</script>

<style scoped>
.chart {
  height: 270px;
}
</style>
