<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <h6>{{ htitle }}</h6>
      <div class="pt-3 chart">
        <canvas :id="chartId" class="chart-canvas" height="190"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { reactive, watch, onMounted, onUnmounted } from "vue";

export default {
  name: "stakebar",
  props: {
    chartId: {
      type: String,
      required: true,
    },
    esgH20: {
      type: Number,
    },
    esgH21: {
      type: Number,
    },
    esgH22: {
      type: Number,
    },
    htitle: {
      type: String,
    },
    stitle: {
      type: String,
    },
  },

  setup(props) {
    const chartData = reactive({
     esgH22: props.esgH22,
     esgH21: props.esgH21,
      esgH20: props.esgH20,
    });

    let chartInstance = null;

    const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = Object.values(chartData);
        chartInstance.update();
      }
    };

    watch(
      () => [props.esgH22, props.esgH21, props.esgH20],
      function () {
        chartData.esgH22 = props.esgH22;
        chartData.esgH21= props.esgH21;
        chartData.esgH20 = props.esgH20;
        updateChart();
      }
    );

    const createChart = () => {
      const canvas = document.getElementById(props.chartId);
      if (canvas) {
        const ctx = canvas.getContext("2d");
   

        chartInstance = new Chart(ctx, {
          type: "bar",
          data: {
            labels: ["2020", "2021", "2022"],
            datasets:[
              {
           
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
             backgroundColor: ["#5e72e4", "#fb6340", "#11cdef"],
              data:Object.values(chartData) ,
              maxBarThickness: 12,
           },
            ],
          },

          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
                 title: {
                display: true,
                text: props.stitle,
                color: "#ffffff",
              },
               tooltip: {
            callbacks: {
              label: function (context) {
                let value = context.parsed.y;
                if (value === 100) {
                  return ">75%";
                } else if (value === 75) {
                  return "50-75%";
                } else if (value === 50) {
                  return "25-50%";
                } else if (value === 25) {
                  return "<25%";
                } else {
                  return "";
                }
              },
            },
          },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
              
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
           
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                  font: {
                    size: 12,
                    family: "Open Sans",
                    style: "normal",
                  },
                  color: "#ffffff",

                  
                },
              },
            },
          },
        });
      }
    };
    onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return {
      chartData,
      updateChart,
    };
  },
};
</script>

<style scoped>
.chart {
  height: 200px;
}
</style>