<template>
  <br />
  <div class="py-4 container-fluid" id="esgpdf">
    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex align-items-center">
          <p class="mb-0" style="color: #f5365c!important;">

            <b>{{ companyName }} Key Sustainability Highlights in 2022</b>
          </p>
        </div>

        <br />
        <div class="row">
          <div class="col-lg-12 col-pdf-12">
            <div class="card h-100">
              <div class="p-3 card-body">
                <h2 style="color: #00a758">Intro</h2>
                <div class="pt-3 chart">
                  <p v-if="loading">...Loading</p>
                  <p v-else>{{ answer }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-3 col-pdf-3 col-md-2">
            <div class="card h-60">
              <div class="p-3 card-body">
                <h6>ESG Score 2022</h6>
                <div class="pt-3 chart">
                  Highest rating of our score
                  <h2 style="color: #11cdef !important">{{ esgScore2022 }}</h2>
                  for 2022
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-pdf-3 mb-lg col-12">
            <div class="card h-60">
              <div class="p-3 card-body">
                <h6>Award</h6>
                <div class="pt-3 chart">
                  Awarded the
                  <h2 style="color: #11cdef!important">
                    {{ displayAward }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-pdf-3 col-md-2 col-12">
            <div class="card h-60">
              <div class="p-3 card-body">
                <h6>Energy Management</h6>
                <div class="pt-3 chart">
                  decrease in electricity consumption
                  <h2 style="color: #11cdef !important">{{ percentageE2022 }}%</h2>
                  for 2022
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-pdf-3 mb-lg">
            <div class="card h-60">
              <div class="p-3 card-body">
                <h6>Event</h6>
                <div class="pt-3 chart">
                  Join
                  <h2 style="color: #11cdef !important">
                        {{ displayEvent }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-pdf-3">
          <div class="card h-100">
            <div class="p-3 card-body">
              <h6>Employees' Training & Development (hours)</h6>
              <div class="pt-3 chart">
                <h2 style="color: #11cdef !important">{{ esgTrain2022 }} hours</h2>
                accumulated on <br />
                employees' Training & <br />
                development <br />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-pdf-3 d-flex align-items-end">
          <div class="card h-70 w-100">
            <div class="p-4 card-body">
              <h6>ESG rating 2022</h6>

              <h2 style="color: #11cdef !important">{{ percentageChange2022 }}</h2>
              from 2021
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-pdf-3">
          <div class="card h-100">
            <div class="p-3 card-body">
              <h6>2022 Board of Director</h6>
              <div class="pt-3 chart">
                <h2 style="color: #11cdef !important">{{ esgGenderB22 }} %</h2>
                of our Board of <br />
                Directors are women <br />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-pdf-3 d-flex align-items-end">
          <div class="card h-70 w-100">
            <div class="p-4 card-body">
              <h6>Community Investment</h6>

              <h2 style="color: #11cdef !important">RM {{ esgCommunity2022 }}</h2>
              for 2022
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
  </div>
</template>

<script>
import { listQuestions } from "@/graphql/queries";
import { API, Auth } from "aws-amplify";
import { ref, onMounted, computed } from "vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { listComs } from "@/graphql/queries";



export default {
  name: "dashboard-default",


  methods: {
    async exportToPDF() {
      const pdf = new jsPDF("p", "mm", "a4");
      const element = document.getElementById("esgpdf");
      const canvas = await html2canvas(element, {
        useCORS: true,
        scale: 2,
        height: 1000,
      }); // Set scale to 2 to improve quality on high DPI screens
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 page width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Calculate number of pages based on image height and A4 page height
      const numPages = Math.ceil(imgHeight / 297);

      // Loop through each page and add image to PDF
      for (let i = 0; i < numPages; i++) {
        const position = i * -297; // Calculate position based on page number and A4 page height
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      }

      pdf.save("esg-report.pdf");
    },
  },

  setup() {
    const loading = ref(false);
    const esgRating2022 = ref("");
    const esgRating2021 = ref("");
    const esgRating2020 = ref("");
    const esgTrain2022 = ref("");
    const esgTrain2021 = ref("");
    const esgTrain2020 = ref("");
    const esgScore2022 = ref("");
    const esgScore2021 = ref("");
    const esgScore2020 = ref("");
    const percentageChange2022 = ref("");
    const percentageChange2022b = ref("");
    const percentageChange2021 = ref("");
    const percentageChange2021b = ref("");
    const percentageChangeall = ref("");
    const percentageChangeallb = ref("");
    const esgGenderA20 = ref("");
    const esgGenderB20 = ref("");
    const esgGenderA21 = ref("");
    const esgGenderB21 = ref("");
    const esgGenderA22 = ref("");
    const esgGenderB22 = ref("");
    const esgEnergy2022 = ref("");
    const esgEnergy2021 = ref("");
    const esgCommunity2022 = ref("");
    const percentageE2022 = ref("");
    const inputText = ref("");
    const outputText = ref("");
    const companyName = ref("");
    const qStyle = ref("Professional");
    const question = ref("1000");

    const eventD = ref("Portocol Education ESG Fund");

    const awardValue = ref(" Portocol ESG Award 2022");

    const displayAward = computed(() => {
      return awardValue.value || "";
    });

    const displayEvent = computed(() => {
      return eventD.value || "";
    });

    const getAnswer = async () => {
      try {
        loading.value = true;
        const questionText = `Write about sustainability for ESG in 2022 in ${question.value} characters for ${companyName.value} in ${qStyle.value}  tone.Write about the past year (2022) esg result, including esg rating ${esgScore2022.value}.${percentageE2022.value}  in electric usage,and ${esgGenderA22.value} of directors being women ${percentageChange2022.value}`;
        const response = await API.post("api075c4eb8", "/chatgpt-dev", {
          body: { input: { question: questionText } },
        });
        answer.value = response.Answer;
      } catch (error) {
        console.error("Error fetching answer:", error);
      } finally {
        loading.value = false;
      }
    };

    const fetchData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const response = await API.graphql({
          query: listQuestions,
          variables: {
            filter: {
              userid: {
                eq: userId,
              },
            },
          },

          fetchPolicy: "network-only",
        });

        const response2 = await API.graphql({
          query: listComs,
          variables: {
            filter: {
              userid: {
                eq: userId,
              },
            },
          },
          fetchPolicy: "network-only",
        });

        if (response2.data.listComs.items.length === 1) {
          companyName.value = response2.data.listComs.items[0].Company;
        }

        const questions = response.data.listQuestions.items;

        questions.forEach((question) => {
          if (question.year === 2022) {
            esgRating2022.value = question.esg_rating;
            esgGenderA22.value = question.c3b1a;
            esgGenderB22.value = question.c3b1b;
            esgScore2022.value = question.esg_score;
            esgTrain2022.value = question.c6ag1 + question.c6ag2;
            esgEnergy2022.value = question.c4a;
            esgCommunity2022.value = question.c2a;
          } else if (question.year === 2021) {
            esgRating2021.value = question.esg_rating;
            esgGenderA21.value = question.c3b1a;
            esgGenderB21.value = question.c3b1b;
            esgEnergy2021.value = question.c4a;

            esgScore2021.value = question.esg_score;
            esgTrain2021.value = question.c6ag1 + question.c6ag2;
          } else if (question.year === 2020) {
            esgGenderA20.value = question.c3b1a;
            esgGenderB20.value = question.c3b1b;
            esgRating2020.value = question.esg_rating;
            esgScore2020.value = question.esg_score;
            esgTrain2020.value = question.c6ag1 + question.c6ag2;
          }
        });
        percentageE2022.value = Math.round(
          ((esgEnergy2022.value - esgEnergy2021.value) / esgEnergy2021.value) *
            100,
          2
        );

        percentageChange2022.value =
          ((esgRating2022.value - esgRating2021.value) / esgRating2021.value) *
          100;
        percentageChange2022b.value = Math.round(percentageChange2022.value);
        percentageChange2022.value =
          (percentageChange2022b.value > 0 ? "+" : "") +
          percentageChange2022b.value +
          "%";
        percentageChange2021.value =
          ((esgRating2021.value - esgRating2020.value) / esgRating2020.value) *
          100;
        percentageChange2021b.value = Math.round(percentageChange2021.value);
        percentageChange2021.value =
          (percentageChange2021b.value > 0 ? "+" : "") +
          percentageChange2021b.value +
          "%";
        percentageChangeall.value =
          ((esgRating2022.value - esgRating2020.value) / esgRating2020.value) *
          100;
        percentageChangeallb.value = Math.round(percentageChangeall.value);
        percentageChangeall.value =
          (percentageChangeallb.value > 0 ? "+" : "") +
          percentageChangeallb.value +
          "%";
      } catch (error) {
        console.log(error);
      }
    };
    const answer = ref(
      `In 2022, Malaysia Family Insurance achieved impressive results in its ESG rating. It achieved a 5-11 electric usage rating, and 47 of its directors were women, an increase of 7%. The company also made strides in its environmental initiatives, achieving a carbon intensity reduction of 33%, as well as a commitment to renewable energy. Additionally, in terms of social initiatives, the company has implemented a range of initiatives to benefit its employees and the local community, such as improving healthcare and education, and creating job opportunities. Finally, the company has implemented a number of corporate governance initiatives, such as independent board members and a code of conduct for its employees. In conclusion, Malaysia Family Insurance has made significant progress in its ESG rating in 2022 and is committed to continuing its efforts to improve its ESG rating in the future.`
    );

    onMounted(() => {
      fetchData();
    });

    return {
      esgRating2022,
      esgRating2021,
      esgRating2020,
      esgTrain2022,
      esgTrain2021,
      esgTrain2020,
      esgScore2022,
      esgScore2021,
      esgScore2020,
      percentageChange2022,
      percentageChange2022b,
      percentageChange2021,
      percentageChange2021b,
      percentageChangeall,
      percentageChangeallb,
      esgGenderA20,
      esgGenderB20,
      esgGenderA21,
      esgGenderB21,
      esgGenderA22,
      esgGenderB22,
      esgEnergy2022,
      esgCommunity2022,
      percentageE2022,
      inputText,
      outputText,
      question,
      answer,
      getAnswer,
      companyName,
      awardValue,
      displayAward,
      displayEvent,
      eventD,
      loading,
      qStyle,
    };
  },
};
</script>

<style>
.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
}

.main-content {
  z-index: 1;
}

@media (min-width: 1200px) {
  .sidenav {
    display: block;
  }

  .main-content {
    margin-left: 250px;
    width: calc(100% - 250px);
    z-index: 1000;
  }
}

.chat-gpt {
  max-width: 600px;
  margin: 0 auto;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  border: none;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.col-pdf-12 {
  flex: 0 0 auto;
  width: 100%;
}

.col-pdf-3 {
  flex: 0 0 auto;
  width: 25% !important;
}

.answer {
  margin-top: 1rem;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

h2 {
  margin-bottom: 0.5rem;
}

#esgpdf {
  width: 1200px; /* fixed width */
  height: 400px; /* fixed height */
}

#esgq {
  width: 1200px; /* fixed width */
}
</style>