<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <h6>ESG Rating Overview</h6>
      <p class="text-sm">
        <i class="fa fa-arrow-up text-success"></i>
        <span class="font-weight-bold">{{ percentageCh }}%   </span>
     from  2020-2022
      </p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="chartLine2" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive,watch } from 'vue'
import Chart from 'chart.js/auto'

export default ({
  name: 'GradientLineChart',
 props: {
    esgRating20: {
      type: Number,
    },
    esgRating21: {
      type: Number,
    },
    esgRating22: {
      type: Number,
    },
      percentageCh: {
      type: Number,

      coerce: (value) => Number(value),
    },
  },
 

  setup(props) {
    
    const chartLine = reactive({
      esgRating20: props.esgRating20,
      esgRating21: props.esgRating21,
      esgRating22: props.esgRating22,
    });
let chartInstance = null;
     const updateChart = () => {
      if (chartInstance) {
        chartInstance.data.datasets[0].data = Object.values(chartLine);
        chartInstance.update();
      }
    };

     watch(
      () => [props.esgRating20, props.esgRating21, props.esgRating22],
      function () {
        chartLine.esgRating20 = props.esgRating20;
        chartLine.esgRating21 = props.esgRating21;
        chartLine.esgRating22 = props.esgRating22;
        updateChart();
      }
    );

    const createChart = () => {
       
  const canvas = document.getElementById('chartLine2');
if (canvas) {

      const ctx = canvas.getContext('2d')

      const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50)
      gradientStroke.addColorStop(1, 'rgba(94, 114, 228, 0.2)')
      gradientStroke.addColorStop(0.2, 'rgba(94, 114, 228, 0.0)')
      gradientStroke.addColorStop(0, 'rgba(94, 114, 228, 0)')

      chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['2020', '2021', '2022'],
          datasets: [
            {
              label: 'esg rating',
              tension: 0.4,
              borderWidth: 2,
              pointRadius: 0,
              borderColor: '#11cdef ',
              backgroundColor: gradientStroke,
              fill: true,
               data: Object.values(chartLine),
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#fbfbfb',
                font: {
                  size: 11,
                  family: 'Open Sans',
                  style: 'normal',
                  lineHeight: 2,
                },
              },
            },

            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: '#fbfbfb',
                padding: 20,
                font: {
                  size: 11,
                  family: 'Open Sans',
                  style: 'normal',
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    };}

   onMounted(() => {
      createChart();
    });

    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    });

    return { chartLine, 
    updateChart, };
  },
})
</script>