<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Questions</p>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="updateQuestion">
              <div class="form-group">
                <label class="form-control-label">Year:</label>
                <div>{{ form.year }}</div>
              </div>
              <h3>1. Anti-corruption</h3>
              <br />
              <div class="form-group">
                <label class="form-control-label" for="question1"
                  >What is the percentages of employees who have received
                  training on anti-corruption by employee category?</label
                >
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-control-label" for="c1ag1"
                      >Director %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c1ag1"
                      :value="form.c1ag1"
                      @input="form.c1ag1 = $event.target.value"
                      placeholder="Enter a percentage (0-100)"
                      min="0"
                      max="100"
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="form-control-label" for="c1ag2"
                      >Management %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c1ag2"
                      :value="form.c1ag2"
                      @input="form.c1ag2 = $event.target.value"
                      placeholder="Enter a percentage (0-100)"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label" for="c1ag3"
                      >Employee %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c1ag3"
                      :value="form.c1ag3"
                      @input="form.c1ag3 = $event.target.value"
                      placeholder="Enter a percentage (0-100)"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c1b"
                  >Percentage of operations assessed for corruption-related
                  risks</label
                >
                <argon-input
                  type="number"
                  id="c1b"
                  :value="form.c1b"
                  @input="form.c1b = $event.target.value"
                />
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c1b"
                  >Confirmed incidents of corruption and action taken</label
                >
                <select class="form-control" id="c1c" v-model="form.c1c">
                  <option value="">Select an option</option>
                  <option value="0">None</option>
                  <option value="25">Few incidents with no action taken</option>
                  <option value="50">
                    Some incidents with some action taken
                  </option>
                  <option value="75">
                    Several incidents with appropriate action taken
                  </option>
                  <option value="100">
                    Many incidents with strong and appropriate action taken
                  </option>
                </select>
              </div>
              <br />
              <hr />
              <br />
              <h3>2. Community/Society</h3>
              <br />
              <div class="form-group">
                <label class="form-control-label" for="c2a">
                  Total amount invested in the community where the target
                  beneficiaries are external to the listed issuer
                </label>
                <argon-input
                  type="number"
                  step="any"
                  id="c2a"
                  :value="form.c2a"
                  @input="form.c2a = $event.target.value"
                  placeholder="Enter an amount in RM"
                />
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c2b"
                  >Total number of beneficiaries of the investment in
                  communities</label
                >
                <select class="form-control" id="c2b" v-model="form.c2b">
                  <option value="">Select an option</option>
                  <option value="0">None</option>
                  <option value="25">1-10</option>
                  <option value="50">11-50</option>
                  <option value="75">51-100</option>
                  <option value="100">101+</option>
                </select>
              </div>

              <br />
              <hr />
              <br />
              <h3>3. Diversity</h3>
              <br />
              <p>
                Percentage of employee/directors by gender and age group, for
                each category
              </p>
              <br />
              <div class="form-group">
                <label class="form-control-label"
                  >Employee Gender Percentage</label
                >
                <span v-if="formErrors.c3ag11a">{{ formErrors.c3ag11a }}</span>
                <div class="row">
                  <div class="col-md-2">
                    <label class="form-control-label" for="c3ag11a"
                      >Men %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag11a"
                      :value="form.c3ag11a"
                      @input="form.c3ag11a = $event.target.value"
                      placeholder="Enter a percentage (0-100)"
                      min="0"
                      max="100"
                    />
                  </div>

                  <div class="col-md-2">
                    <label class="form-control-label" for="c3ag11b"
                      >Women %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag11b"
                      :value="form.c3ag11b"
                      @input="form.c3ag11b = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
              <p
                v-if="
                  etotalGenderPercent !== 100 &&
                  form.c3ag11a != null &&
                  form.c3ag11b != null
                "
                class="error-message"
              >
                The total percentage must be 100%.
              </p>
              <div class="form-group">
                <label class="form-control-label"
                  >Employee Age Group Percentage</label
                >
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3ag12a">
                      &lt;30 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag12a"
                      :value="form.c3ag12a"
                      @input="form.c3ag12a = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3ag12b"
                      >31-40 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag12b"
                      :value="form.c3ag12b"
                      @input="form.c3ag12b = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3ag12c"
                      >41-50 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag12c"
                      :value="form.c3ag12c"
                      @input="form.c3ag12c = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="form.c3ag12d">
                      >50 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag12d"
                      :value="form.c3ag12d"
                      @input="form.c3ag12d = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
              <p
                v-if="
                  etotalAgePercent !== 100 &&
                  form.c3ag12a != null &&
                  form.c3ag12b != null &&
                  form.c3ag12c != null &&
                  form.c3ag12d != null
                "
                class="error-message"
              >
                The total percentage must be 100%.
              </p>

              <div class="form-group">
                <label class="form-control-label"
                  >Management Gender Percentage</label
                >

                <div class="row">
                  <div class="col-md-2">
                    <label class="form-control-label" for="c3ag21a"
                      >Men %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag21a"
                      :value="form.c3ag21a"
                      @input="form.c3ag21a = $event.target.value"
                      placeholder="Enter a percentage (0-100)"
                      min="0"
                      max="100"
                    />
                  </div>

                  <div class="col-md-2">
                    <label class="form-control-label" for="c3ag21b"
                      >Women %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag21b"
                      :value="form.c3ag21b"
                      @input="form.c3ag21b = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
              <p
                v-if="
                  mtotalGenderPercent !== 100 &&
                  form.c3ag21a != null &&
                  form.c3ag21b != null
                "
                class="error-message"
              >
                The total percentage must be 100%.
              </p>
              <div class="form-group">
                <label class="form-control-label"
                  >Management Age Group Percentage</label
                >
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3ag22a">
                      &lt;30 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag22a"
                      :value="form.c3ag22a"
                      @input="form.c3ag22a = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3ag22b"
                      >31-40 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag22b"
                      :value="form.c3ag22b"
                      @input="form.c3ag22b = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3ag22c"
                      >41-50 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag22c"
                      :value="form.c3ag22c"
                      @input="form.c3ag22c = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="form.c3ag22d">
                      >50 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag22d"
                      :value="form.c3ag22d"
                      @input="form.c3ag22d = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
              <p
                v-if="
                  mtotalAgePercent !== 100 &&
                  form.c3ag22a != null &&
                  form.c3ag22b != null &&
                  form.c3ag22c != null &&
                  form.c3ag22d != null
                "
                class="error-message"
              >
                The total percentage must be 100%.
              </p>

              <div class="form-group">
                <label class="form-control-label"
                  >Directors Gender Percentage</label
                >

                <div class="row">
                  <div class="col-md-2">
                    <label class="form-control-label" for="c3b1a">Men %</label>
                    <argon-input
                      type="number"
                      step="any"
                      id="c3b1a"
                      :value="form.c3b1a"
                      @input="form.c3b1a = $event.target.value"
                      placeholder="Enter a percentage (0-100)"
                      min="0"
                      max="100"
                    />
                  </div>

                  <div class="col-md-2">
                    <label class="form-control-label" for="c3b1b"
                      >Women %</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3b1b"
                      :value="form.c3b1b"
                      @input="form.c3b1b = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
              <p
                v-if="
                  dtotalGenderPercent !== 100 &&
                  form.c3b1b != null &&
                  form.c3b1a != null
                "
                class="error-message"
              >
                The total percentage must be 100%.
              </p>
              <div class="form-group">
                <label class="form-control-label"
                  >Directors Age Group Percentage</label
                >
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3b2a">
                      &lt;30 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3b2a"
                      :value="form.c3b2a"
                      @input="form.c3b2a = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3ag2b"
                      >31-40 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3ag22b"
                      :value="form.c3b2b"
                      @input="form.c3b2b = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="c3b2c"
                      >41-50 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3b2c"
                      :value="form.c3b2c"
                      @input="form.c3b2c = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label" for="form.c3b2d">
                      >50 yrs</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c3b2d"
                      :value="form.c3b2d"
                      @input="form.c3b2d = $event.target.value"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
              <p
                v-if="
                  dtotalAgePercent !== 100 &&
                  form.c3b2a != null &&
                  form.c3b2b != null &&
                  form.c3b2c != null &&
                  form.c3b2d != null
                "
                class="error-message"
              >
                The total percentage must be 100%.
              </p>
              <br />
              <hr />
              <br />
              <h3>4. Energy Management</h3>
              <br />

              <div class="form-group">
                <label class="form-control-label" for="c4a">
                  Total Energy Consumption (kWh)
                </label>
                <argon-input
                  type="number"
                  step="any"
                  id="c4a"
                  :value="form.c4a"
                  @input="form.c4a = $event.target.value"
                  placeholder="Enter the total energy consumption in kWh"
                />
              </div>

              <br />
              <hr />
              <br />
              <h3>5. Health and Safety</h3>
              <br />
              <div class="form-group">
                <label class="form-control-label" for="c5a">
                  Number of work-related fatalities
                </label>
                <select class="form-control" id="c5a" v-model="form.c5a">
                  <option value="">Select an option</option>
                  <option value="100">None</option>
                  <option value="75">1-3</option>
                  <option value="50">4-6</option>
                  <option value="25">7 or more</option>
                </select>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c5b">
                  Lost time incident rate
                </label>
                <select class="form-control" id="c5b" v-model="form.c5b">
                  <option value="">Select an option</option>
                  <option value="100">No lost time incidents</option>
                  <option value="75">
                    Less than 1 incident per 1 million hours worked
                  </option>
                  <option value="50">
                    Between 1 and 2 incidents per 1 million hours worked
                  </option>
                  <option value="25">
                    More than 2 incidents per 1 million hours worked
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c5c">
                  Number of employees trained on health and safety standards
                </label>
                <select class="form-control" id="c5c" v-model="form.c5c">
                  <option value="">Select an option</option>
                  <option value="25">0-25%</option>
                  <option value="50">26-50%</option>
                  <option value="75">51-75%</option>
                  <option value="100">76-100%</option>
                </select>
              </div>
              <br />
              <hr />
              <br />
              <h3>6. Labour practices and standards</h3>
              <br />
              <div class="form-group">
                <label>Total hours of training</label>
                <div class="row">
                  <div class="col-md-4">
                    <label for="c6ag1">Employee</label>
                    <argon-input
                      type="number"
                      step="any"
                      id="c6ag1"
                      :value="form.c6ag1"
                      @input="form.c6ag1 = $event.target.value"
                      placeholder="Enter number of hours"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="c6ag2">Management</label>
                    <argon-input
                      type="number"
                      step="any"
                      id="c6ag2"
                      :value="form.c6ag2"
                      @input="form.c6ag2 = $event.target.value"
                      placeholder="Enter number of hours"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label" for="c6b"
                  >Percentage of employee that are contractors or temporary
                  staff</label
                >
                <argon-input
                  type="number"
                  id="c6b"
                  :value="form.c6b"
                  @input="form.c6b = $event.target.value"
                  placeholder="Enter a percentage (0-100)"
                  min="0"
                  max="100"
                />
              </div>

              <div class="form-group">
                <label>Total number of employee turnover</label>
                <div class="row">
                  <div class="col-md-4">
                    <label for="c6cg1">Employee</label>
                    <argon-input
                      type="number"
                      step="any"
                      id="c6cg1"
                      :value="form.c6cg1"
                      @input="form.c6cg1 = $event.target.value"
                      placeholder="Enter number of employee turnover"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="c6cg2">Management</label>
                    <argon-input
                      type="number"
                      step="any"
                      id="c6cg2"
                      :value="form.c6cg2"
                      @input="form.c6cg2 = $event.target.value"
                      placeholder="Enter number of management turnover"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c6d">
                  Number of substantiated complaints concerning human rights
                  violations
                </label>
                <select class="form-control" id="c6d" v-model="form.c6d">
                  <option value="">Select an option</option>
                  <option value="100">None</option>
                  <option value="75">1-3</option>
                  <option value="50">4-6</option>
                  <option value="25">7 or more</option>
                </select>
              </div>
              <br />
              <hr />
              <br />
              <h3>7. Supply Chain Management</h3>
              <br />

              <div class="form-group">
                <label class="form-control-label" for="c7a">
                  Proportion of spending on local suppliers
                </label>
                <select class="form-control" id="c7a" v-model="form.c7a">
                  <option value="">Select an option</option>
                  <option value="25">Less than 25%</option>
                  <option value="50">Between 25% and 50%</option>
                  <option value="75">Between 50% and 75%</option>
                  <option value="100">More than 75%</option>
                </select>
              </div>

              <br />
              <hr />
              <br />
              <h3>8. Data privacy and security</h3>
              <br />

              <div class="form-group">
                <label class="form-control-label" for="c8a">
                  Number of substantiated complaints concerning breaches of
                  customer privacy and losses of customer data
                </label>
                <select class="form-control" id="c8a" v-model="form.c8a">
                  <option value="">Select an option</option>
                  <option value="100">None</option>
                  <option value="75">1-3</option>
                  <option value="50">4-6</option>
                  <option value="25">7 or more</option>
                </select>
              </div>

              <br />
              <hr />
              <br />
              <h3>9. Water</h3>
              <br />

              <div class="form-group">
                <label class="form-control-label" for="c9a"
                  >Total volume of water used (m3)</label
                >
                <argon-input
                  type="number"
                  step="any"
                  id="c9a"
                  :value="form.c9a"
                  @input="form.c9a = $event.target.value"
                  placeholder="Enter the total volume of water used"
                ></argon-input>
              </div>

              <br />
              <hr />
              <br />
              <h3>10. Waste Management</h3>
              <br />

              <div class="form-group">
                <div class="row">
                  <div class="col-md-5">
                    <label for="c10a1"
                      >Total waste diverted from disposal (kg)
                    </label>
                    <argon-input
                      type="number"
                      step="any"
                      id="c10a1"
                      :value="form.c10a1"
                      @input="form.c10a1 = $event.target.value"
                      placeholder="Enter number of employee turnover"
                    />
                  </div>
                  <div class="col-md-5">
                    <label for="c10a2"
                      >Total waste directed to disposal (kg)</label
                    >
                    <argon-input
                      type="number"
                      step="any"
                      id="c10a2"
                      :value="form.c10a2"
                      @input="form.c10a2 = $event.target.value"
                      placeholder="Enter number of management turnover"
                    />
                  </div>
                </div>
              </div>

              <br />
              <hr />
              <br />
              <h3>11. Emissions Management</h3>
              <br />

              <div class="form-group">
                <label class="form-control-label" for="c11a"
                  >Scope 1 emission in tonnes of CO2e</label
                >
                <argon-input
                  type="number"
                  step="any"
                  id="c11a"
                  :value="form.c11a"
                  @input="form.c11a = $event.target.value"
                  placeholder=""
                ></argon-input>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c11b"
                  >Scope 2 emission in tonnes of CO2e</label
                >
                <argon-input
                  type="number"
                  step="any"
                  id="c11b"
                  :value="form.c11b"
                  @input="form.c11b = $event.target.value"
                  placeholder=""
                ></argon-input>
              </div>

              <div class="form-group">
                <label class="form-control-label" for="c11c"
                  >Scope 3 emission in tonnes of CO2e (at least for the
                  categories of business travel and employee commuting)</label
                >
                <argon-input
                  type="number"
                  step="any"
                  id="c11c"
                  :value="form.c11c"
                  @input="form.c11c = $event.target.value"
                  placeholder=""
                ></argon-input>
              </div>

              <div v-if="shows5s8FormGroup">
                <br />
                <hr />
                <br />
                <h3 v-if="shows5s8FormGroup">Data Input Sector Specific</h3>
                <br />

                <br />
                <h3 v-if="shows5s8FormGroup">1. Materials</h3>
                <br />
              </div>

              <div class="form-group" v-if="shows5s8FormGroup">
                <label class="form-control-label" for="s5a"
                  >Total weight or volume of material that are used to produce
                  and package products and services</label
                >
                <argon-input
                  type="number"
                  id="s5a"
                  :value="form.s5a"
                  @input="form.s5a = $event.target.value"
                />
              </div>
              <div v-if="shows5s8FormGroup">
                <br />
                <hr />
                <br />
                <h3 v-if="shows5s8FormGroup">2 Effluents</h3>
                <br />
              </div>
              <div class="form-group" v-if="shows5s8FormGroup">
                <label class="form-control-label" for="s8a"
                  >Total volume of water (effluent) discharge over the reporting
                  period</label
                >
                <argon-input
                  type="number"
                  id="s5a"
                  :value="form.s8a"
                  @input="form.s8a = $event.target.value"
                />
              </div>

              <div class="text-center">
                <argon-button type="submit" color="primary" class="my-4"
                  >Update</argon-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { updateQuestion } from "@/graphql/mutations";
import { getQuestion } from "@/graphql/queries";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listComs } from "@/graphql/queries";

export default {
  name: "EditQuestions",
  components: { ArgonInput, ArgonButton },
  data() {
    return {
      question: null,
      form: {
        c1ag1: 0,
        c1ag2: 0,
        c1ag3: 0,
        c1b: 0,
        c1c: 0,
        c3ag11a: 0,
        c3ag11b: 0,

        // add other fields to update here
      },
      formErrors: {},
      shows5s8FormGroup: false,
    };
  },
  computed: {
    scope1EmissionsScore() {
      const emissions = this.form.c11a;

      if (emissions < 5000) {
        return 100 * 0.05;
      } else if (emissions >= 5000 && emissions < 10000) {
        return 75 * 0.05;
      } else if (emissions >= 10000 && emissions < 50000) {
        return 50 * 0.05;
      } else {
        return 25 * 0.05;
      }
    },
    scope2EmissionsScore() {
      const emissions2 = this.form.c11b;

      if (emissions2 < 5000) {
        return 100 * 0.03;
      } else if (emissions2 >= 5000 && emissions2 < 10000) {
        return 75 * 0.03;
      } else if (emissions2 >= 10000 && emissions2 < 50000) {
        return 50 * 0.03;
      } else {
        return 25 * 0.03;
      }
    },
    scope3EmissionsScore() {
      const emissions3 = this.form.c11c;

      if (emissions3 < 5000) {
        return 100 * 0.02;
      } else if (emissions3 >= 5000 && emissions3 < 10000) {
        return 75 * 0.02;
      } else if (emissions3 >= 10000 && emissions3 < 50000) {
        return 50 * 0.02;
      } else {
        return 25 * 0.02;
      }
    },
    totalWasteGenerated() {
      const totalWaste = this.form.c10a1 + this.form.c10a2 || 0;
      const totalDis = (this.form.c10a1 / totalWaste) * 100 || 0;
      if (totalWaste < 1000) {
        return 100 * 0.05 + totalDis * 0.05;
      } else if (totalWaste >= 1000 && totalWaste < 5000) {
        return 75 * 0.05;
      } else if (totalWaste >= 5000 && totalWaste < 10000) {
        return 50 * 0.05;
      } else {
        return 25 * 0.05;
      }
    },
    totalWaterUsage() {
      if (this.form.c9a < 10000) {
        return 100 * 0.05;
      } else if (this.form.c9a >= 10000 && this.form.c9a < 50000) {
        return 75 * 0.05;
      } else if (this.form.c9a >= 50000 && this.form.c9a < 100000) {
        return 50 * 0.05;
      } else {
        return 25 * 0.05;
      }
    },
    TotalTurnover() {
      const employeeT = parseInt(this.form.c6cg1) || 0;
      const managementT = parseInt(this.form.c6cg2) || 0;
      const totalHourst = employeeT + managementT;
      if (totalHourst < 11) {
        return 100 * 0.02;
      } else if (totalHourst >= 11 && totalHourst <= 20) {
        return 75 * 0.02;
      } else if (totalHourst >= 21 && totalHourst <= 30) {
        return 50 * 0.02;
      } else {
        return 0;
      }
    },
    TotalTrainingHours() {
      const employeeHours = parseInt(this.form.c6ag1) || 0;
      const managementHours = parseInt(this.form.c6ag2) || 0;
      const totalHours = employeeHours + managementHours;
      if (totalHours < 11) {
        return 25 * 0.02;
      } else if (totalHours >= 11 && totalHours <= 20) {
        return 50 * 0.02;
      } else if (totalHours >= 21 && totalHours <= 30) {
        return 75 * 0.02;
      } else {
        return 100 * 0.02;
      }
    },
    TotalEnergy() {
      if (this.form.c4a < 10000000) {
        return 100 * 0.15;
      } else if (this.form.c4a >= 10000000 && this.form.c4a < 50000000) {
        return 75 * 0.15;
      } else if (this.form.c4a >= 50000000 && this.form.c4a < 100000000) {
        return 50 * 0.15;
      } else {
        return 25 * 0.15;
      }
    },
    AmountBeneficiaries() {
      const amount = parseFloat(this.form.c2a);
      if (amount < 10000) {
        return 25 * 0.15;
      } else if (amount >= 10000 && amount < 50000) {
        return 50 * 0.15;
      } else if (amount >= 50000 && amount < 100000) {
        return 75 * 0.15;
      } else {
        return 100 * 0.15;
      }
    },

    etotalGenderPercent() {
      return parseInt(this.form.c3ag11a) + parseInt(this.form.c3ag11b);
    },
    mtotalGenderPercent() {
      return parseInt(this.form.c3ag21a) + parseInt(this.form.c3ag21b);
    },
    dtotalGenderPercent() {
      return parseInt(this.form.c3b1a) + parseInt(this.form.c3b1b);
    },
    etotalAgePercent() {
      return (
        parseFloat(this.form.c3ag12a) +
        parseFloat(this.form.c3ag12b) +
        parseFloat(this.form.c3ag12c) +
        parseFloat(this.form.c3ag12d)
      );
    },
    mtotalAgePercent() {
      return (
        parseFloat(this.form.c3ag22a) +
        parseFloat(this.form.c3ag22b) +
        parseFloat(this.form.c3ag22c) +
        parseFloat(this.form.c3ag22d)
      );
    },
    dtotalAgePercent() {
      return (
        parseFloat(this.form.c3b2a) +
        parseFloat(this.form.c3b2b) +
        parseFloat(this.form.c3b2c) +
        parseFloat(this.form.c3b2d)
      );
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    try {
      const response = await API.graphql(graphqlOperation(getQuestion, { id }));
      this.question = response.data.getQuestion;
      this.form = {
        year: this.question.year,
        c1ag1: this.question.c1ag1,
        c1ag2: this.question.c1ag2,
        c1ag3: this.question.c1ag3,
        c1b: this.question.c1b,
        c1c: this.question.c1c,
        c2a: this.question.c2a,
        c2b: this.question.c2b,
        c3ag11a: this.question.c3ag11a,
        c3ag11b: this.question.c3ag11b,
        c3ag12a: this.question.c3ag12a,
        c3ag12b: this.question.c3ag12b,
        c3ag12c: this.question.c3ag12c,
        c3ag12d: this.question.c3ag12d,
        c3ag21a: this.question.c3ag21a,
        c3ag21b: this.question.c3ag21b,
        c3ag22a: this.question.c3ag22a,
        c3ag22b: this.question.c3ag22b,
        c3ag22c: this.question.c3ag22c,
        c3ag22d: this.question.c3ag22d,
        c3b1a: this.question.c3b1a,
        c3b1b: this.question.c3b1b,
        c3b2a: this.question.c3b2a,
        c3b2b: this.question.c3b2b,
        c3b2c: this.question.c3b2c,
        c3b2d: this.question.c3b2d,
        c4a: this.question.c4a,
        c5a: this.question.c5a,
        c5b: this.question.c5b,
        c5c: this.question.c5c,
        c6ag1: this.question.c6ag1,
        c6ag2: this.question.c6ag2,
        c6b: this.question.c6b,
        c6cg1: this.question.c6cg1,
        c6cg2: this.question.c6cg2,
        c6d: this.question.c6d,
        c7a: this.question.c7a,
        c8a: this.question.c8a,
        c9a: this.question.c9a,
        c10a1: this.question.c10a1,
        c10a2: this.question.c10a2,
        c11a: this.question.c11a,
        c11b: this.question.c11b,
        c11c: this.question.c11c,
        esgRating: this.question.esg_rating,
        esgERating: this.question.esg_Erating,
        esgSRating: this.question.esg_Srating,
        esgGRating: this.question.esg_Grating,
        esgScore: this.question.esg_score,
        // add other fields to update here
      };
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;
      const responseComs = await API.graphql(
        graphqlOperation(listComs, { userid: userId })
      );
      const portfolio = responseComs.data.listComs.items[0].Portfolio;

      // Check if c1b and c1c fields should be shown or not based on portfolio value
      if (portfolio === 9) {
        this.shows5s8FormGroup = true;
      } else {
        this.shows5s8FormGroup = false;
        this.form.s8a = null; // clear value if not shown
        this.form.s5a = null; // clear value if not shown
      }
    } catch (error) {
      console.log("Error getting question...", error);
    }
  },
  watch: {
    form: {
      handler(newForm) {
        const fieldsToWatch = [
          "c1ag1",
          "c1ag3",
          "c1b",
          "c1ag2",
          "c3ag11a",
          "c3ag11b",
          "c3ag12a",
          "c3ag12b",
          "c3ag12c",
          "c3ag12d",
          "c3ag21a",
          "c3ag21b",
          "c3ag22a",
          "c3ag22b",
          "c3ag22c",
          "c3ag22d",
          "c3b1a",
          "c3b1b",
          "c3b2a",
          "c3b2b",
          "c3b2c",
          "c3b2d",
          "c6b",
        ];
        fieldsToWatch.forEach((field) => {
          if (newForm[field] < 0 || newForm[field] > 100) {
            this.formErrors[field] = "Please enter a value between 0 and 100.";
            this.form[field] = "";
          } else {
            this.formErrors[field] = null;
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    async updateQuestion() {
      const { id } = this.$route.params;
      const {
        c1ag1,
        c1ag2,
        c1ag3,
        c1b,
        c1c,
        c2a,
        c2b,
        c3ag11a,
        c3ag11b,
        c3ag12a,
        c3ag12b,
        c3ag12c,
        c3ag12d,
        c3ag21a,
        c3ag21b,
        c3ag22a,
        c3ag22b,
        c3ag22c,
        c3ag22d,
        c3b1a,
        c3b1b,
        c3b2a,
        c3b2b,
        c3b2c,
        c3b2d,
        c4a,
        c5a,
        c5b,
        c5c,
        c6ag1,
        c6ag2,
        c6b,
        c6cg1,
        c6cg2,
        c6d,
        c7a,
        c8a,
        c9a,
        c10a1,
        c10a2,
        c11a,
        c11b,
        c11c,
      } = this.form;

      const director = parseFloat(this.form.c1ag1) * 0.0111;
      const management = parseFloat(this.form.c1ag2) * 0.0111;
      const employee = parseFloat(this.form.c1ag3) * 0.0111;
      const operationsAssessed = parseFloat(this.form.c1b) * 0.0333;
      const confirmedIncidents = parseFloat(this.form.c1c) * 0.0333;

      const NoBeneficiaries = parseFloat(this.form.c2b) * 0.15;
      const employeeGender =
        (100 -
          Math.abs(
            parseFloat(this.form.c3ag11a) - parseFloat(this.form.c3ag11b)
          )) *
        0.005;
      const employeeAge =
        (100 -
          Math.abs(25 - parseFloat(this.form.c3ag12a)) -
          Math.abs(25 - parseFloat(this.form.c3ag12b)) -
          Math.abs(25 - parseFloat(this.form.c3ag12c)) -
          Math.abs(25 - parseFloat(this.form.c3ag12d))) *
        0.005;
      const MGender =
        (100 -
          Math.abs(
            parseFloat(this.form.c3ag21a) - parseFloat(this.form.c3ag21b)
          )) *
        0.005;
      const MAge =
        (100 -
          Math.abs(25 - parseFloat(this.form.c3ag22a)) -
          Math.abs(25 - parseFloat(this.form.c3ag22b)) -
          Math.abs(25 - parseFloat(this.form.c3ag22c)) -
          Math.abs(25 - parseFloat(this.form.c3ag22d))) *
        0.005;
      const DGender =
        (100 -
          Math.abs(parseFloat(this.form.c3b1a) - parseFloat(this.form.c3b1b))) *
        0.005;
      const DAge =
        (100 -
          Math.abs(25 - parseFloat(this.form.c3b2a)) -
          Math.abs(25 - parseFloat(this.form.c3b2b)) -
          Math.abs(25 - parseFloat(this.form.c3b2c)) -
          Math.abs(25 - parseFloat(this.form.c3b2d))) *
        0.005;

      const Nofatalities = this.form.c5a * 0.02;
      const Ltime = this.form.c5b * 0.015;
      const TrainHealth = this.form.c5c * 0.015;
      const Contractor = parseFloat(this.form.c6b) * 0.015;
      const Complain = parseFloat(this.form.c6d) * 0.015;
      const Local = parseFloat(this.form.c7a) * 0.07;
      const Privacy = parseFloat(this.form.c8a) * 0.08;
      const esg =
        director +
        management +
        employee +
        operationsAssessed +
        confirmedIncidents +
        this.AmountBeneficiaries +
        employeeGender +
        employeeAge +
        MAge +
        MGender +
        DAge +
        DGender +
        NoBeneficiaries +
        Nofatalities +
        Ltime +
        TrainHealth +
        Contractor +
        Complain +
        Local +
        Privacy +
        this.scope1EmissionsScore +
        this.scope2EmissionsScore +
        this.scope3EmissionsScore +
        this.totalWasteGenerated +
        this.totalWaterUsage +
        this.TotalTrainingHours +
        this.TotalTurnover +
        this.TotalEnergy;
      const esg1 =
        this.scope1EmissionsScore +
        this.scope2EmissionsScore +
        this.scope3EmissionsScore +
        this.totalWasteGenerated +
        this.totalWaterUsage +
        this.TotalTrainingHours +
        this.TotalTurnover +
        this.TotalEnergy;
      const esg2 = NoBeneficiaries + this.AmountBeneficiaries;
      const esg3 =
        director +
        management +
        employee +
        operationsAssessed +
        confirmedIncidents +
        employeeGender +
        employeeAge +
        MAge +
        MGender +
        DAge +
        DGender +
        Nofatalities +
        Ltime +
        TrainHealth +
        Contractor +
        Complain +
        Local +
        Privacy;

      const esgRating = isNaN(esg) ? "" : esg.toFixed(2);
      const esgERating = isNaN(esg1) ? "" : esg1.toFixed(2);
      const esgSRating = isNaN(esg2) ? "" : esg2.toFixed(2);
      const esgGRating = isNaN(esg3) ? "" : esg3.toFixed(2);

      let esgScore = "";

      if (esgRating >= 90 && esgRating <= 100) {
        esgScore = "5";
      } else if (esgRating >= 70 && esgRating < 90) {
        esgScore = "4";
      } else if (esgRating >= 50 && esgRating < 70) {
        esgScore = "3";
      } else if (esgRating >= 30 && esgRating < 50) {
        esgScore = "2";
      } else if (esgRating >= 0 && esgRating < 30) {
        esgScore = "1";
      }

      const input = {
        id,
        c1ag1,
        c1ag2,
        c1ag3,
        c1b,
        c1c,
        c2a,
        c2b,
        c3ag11a,
        c3ag11b,
        c3ag12a,
        c3ag12b,
        c3ag12c,
        c3ag12d,
        c3ag21a,
        c3ag21b,
        c3ag22a,
        c3ag22b,
        c3ag22c,
        c3ag22d,
        c3b1a,
        c3b1b,
        c3b2a,
        c3b2b,
        c3b2c,
        c3b2d,
        c4a,
        c5a,
        c5b,
        c5c,
        c6ag1,
        c6ag2,
        c6b,
        c6cg1,
        c6cg2,
        c6d,
        c7a,
        c8a,
        c9a,
        c10a1,
        c10a2,
        c11a,
        c11b,
        c11c,
        esg_rating: esgRating,
        esg_Erating: esgERating,
        esg_Srating: esgSRating,
        esg_Grating: esgGRating,
        esg_score: esgScore,
      };

      if (
        this.etotalGenderPercent !== 100 ||
        this.etotalAgePercent !== 100 ||
        this.mtotalGenderPercent !== 100 ||
        this.mtotalAgePercent !== 100 ||
        this.dtotalGenderPercent !== 100 ||
        this.dtotalAgePercent !== 100
      ) {
        alert(
          "Total percentage must equal to 100% for gender and 100% for age group."
        );
        return;
      }
      try {
        const response = await API.graphql(
          graphqlOperation(updateQuestion, { input })
        );
        console.log("Question updated!", response.data.updateQuestion);
        alert("Upadted");
        // redirect to question details page
      } catch (error) {
        console.log("Error updating question...", error);
        this.formErrors = error.errors;
        alert(error);
      }
    },
  },
};
</script>

<style>
.error-message {
  font-size: 14px;
  color: red;
}
</style>
 
 
