<script setup >
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
</script>
<template>
  <authenticator hideSignUp="{true}">
    <template v-slot:header>
      <div
        style="
          padding: var(--amplify-space-large);
          text-align: center;
          color: white;
        "
      >
        ESG Dashboard
      </div>
    </template>


  </authenticator>
</template>



<script  >
// Create company when user signs up

export default {
  name: "Auth2",
  components: {
    Authenticator,
  },
};
</script>

<style>

.amplify-button[data-variation='primary'] {
  background: #df4a43;
  
}
</style>