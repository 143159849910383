/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEsgScore = /* GraphQL */ `
  query GetEsgScore($id: ID!) {
    getEsgScore(id: $id) {
      id
      Esg_rating
      Esg_score
      Esg_dif
      year
      userid
      createdAt
      updatedAt
    }
  }
`;
export const listEsgScores = /* GraphQL */ `
  query ListEsgScores(
    $filter: ModelEsgScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEsgScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Esg_rating
        Esg_score
        Esg_dif
        year
        userid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      userid
      c1ag1
      c1b
      c1c
      c2a
      c2b
      c3ag11a
      c3ag11b
      c3ag12a
      c3ag12b
      c3ag12c
      c3ag12d
      c3ag21a
      c3ag21b
      c3ag22d
      c3ag22c
      c3ag22b
      c3ag22a
      c3b1a
      c3b1b
      c3b2a
      c3b2b
      c3b2c
      c3b2d
      c4a
      c5a
      c5b
      c5c
      c6ag1
      c6ag2
      c6b
      c6cg1
      c6cg2
      c6d
      c7a
      c8a
      c9a
      c10a
      c10a1
      c10a2
      c11a
      c11b
      c11c
      s1a
      s1b
      s1c
      s1d
      s2a
      s3a
      s3b
      s3c
      s4a
      s5a
      s6a
      s6b
      s6c
      s6d
      s7a
      s7b
      s8a
      year
      c1ag2
      c1ag3
      esg_rating
      esg_score
      esg_Erating
      esg_Srating
      esg_Grating
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userid
        c1ag1
        c1b
        c1c
        c2a
        c2b
        c3ag11a
        c3ag11b
        c3ag12a
        c3ag12b
        c3ag12c
        c3ag12d
        c3ag21a
        c3ag21b
        c3ag22d
        c3ag22c
        c3ag22b
        c3ag22a
        c3b1a
        c3b1b
        c3b2a
        c3b2b
        c3b2c
        c3b2d
        c4a
        c5a
        c5b
        c5c
        c6ag1
        c6ag2
        c6b
        c6cg1
        c6cg2
        c6d
        c7a
        c8a
        c9a
        c10a
        c10a1
        c10a2
        c11a
        c11b
        c11c
        s1a
        s1b
        s1c
        s1d
        s2a
        s3a
        s3b
        s3c
        s4a
        s5a
        s6a
        s6b
        s6c
        s6d
        s7a
        s7b
        s8a
        year
        c1ag2
        c1ag3
        esg_rating
        esg_score
        esg_Erating
        esg_Srating
        esg_Grating
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCom = /* GraphQL */ `
  query GetCom($id: ID!) {
    getCom(id: $id) {
      id
      Company
      Location
      Portfolio
      userid
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listComs = /* GraphQL */ `
  query ListComs(
    $filter: ModelComFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Company
        Location
        Portfolio
        userid
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
