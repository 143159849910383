import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import ArgonDashboard from './argon-dashboard';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-vue';
import store from './store';


Amplify.configure(awsExports);

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ArgonDashboard);
app.mount('#app');


